import {
  Box,
  useColorModeValue,
  Stack,
  Container,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";


//Language files
import { artheritage  } from "../../constants/constants";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import { AnnualEditions } from "./AnnualEditions";
import { EditionBody } from "./BodyOfWork/EditionsTimeline";

export default function BodyofWork({ children }) {
  const [flag] = useState(0);
  const {alias}=useParams();
  const masterlist = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];


  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <BodyOfWorkBody
          flag={flag}
          masterlist={masterlist}
          masterlistType={masterlistType}
          aliasAPI={alias}
        />
      </Box>
    </Box>
  );
}

export const BodyOfWorkBody = ({ 
  flag, 
  masterlist, 
  masterlistType,
}) => {

  const [modalcard, setModalcard] = useState(false);
  let { tabValue } = useParams();
  const [contentImage, setContentImage] = useState(null)


  function formatData() {
    return [
      {
        name: `${artheritage.ANNUAL_EDITION}`,
        component:<AnnualEditions masterlist={masterlist} masterlistType={masterlistType} setContentImage={setContentImage}/>,
      }
    ];
}


const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(masterlist){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist])

  return (
    <Stack position={"relative"} width={"100%"} minH={"calc(100vh - 64px)"}>
        <Container minW={"100%"} pb="4" px={{base:"4",md:"0px"}} marginX={"0px"}>
      <Stack width={{ base: "100%%", md: "1225px" }} px={{ base: "0", md: "64px" }}>
        {tabValue ? (
          <Box
          minHeight={{xl:"100%", '2xl':"100%)"}}
            px="0px"
          >
           <EditionBody masterlist={masterlist} masterlistType={masterlistType} artAlias={tabValue} contentImageUrl={contentImage}/>
          </Box>
        ) : (
          <TabMain width={{ base: "100%%", md: "1040px" }}  paddingTop="15px" gap="50px" pos={"fixed"}
          panelTop={{ base: "65px", sm: "85px", md: "65px" }} topTogleIcon="15px" listId={"listgridbutton6"} flag={flag} data={tabPanelData} modalcard={modalcard}  setModalcard={setModalcard} />
        )}
        </Stack>
      </Container>
    </Stack>
  );
};
