import { Box, HStack, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";
import { isArray } from "lodash";
import Small from "../../../components/layouts/Typography/Small";
import { Link } from "react-router-dom";
import { bibliography } from "../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

export default function DrawerBody({
  setSwiper,
  data,
  handleBookClick,
  bibliographyObject,
}) {
  return (
    <>
      <Stack position={"relative"} id="allsliderskkArchive">
      {data?.body &&
          data?.body.map((it, index) => (
            <HStack cursor={"pointer"} key={index}>
              <Text
                fontSize="12px"
                className="autherlink"
                color="color.blue"
                lineHeight={"15px"}
                onClick={(e) => {
                  // handleContentClicked(index, it.text);
                  // setShowSubscribeModal(true)
                }}
              >
                {it.text}
              </Text>
            </HStack>
          ))}
       {data?.sliderImage &&  <Swiper
          effect={"fade"}
          grabCursor={true}
          navigation={true}
          modules={[EffectFade, Navigation]}
          style={{ marginTop: "32px" }}
          onSwiper={setSwiper}
        >
          {data?.sliderImage && isArray(data?.sliderImage) &&
            data?.sliderImage.map((it) => (
              <SwiperSlide>
                <Box
                  justifyItems="center"
                  display="flex"
                  height={"385px"}
                  bg="white"
                  justifyContent="space-around"
                  onClick={()=> handleBookClick(true, data)}

                >
                  <Image
                    src={it}
                    alt=""
                    cursor={"pointer"}
                    transition="1s ease"
                    width={"auto"}
                    height={"inherit"}
                    objectFit={"contain"}
                    fallbackSrc={placeholderImg}
                    // cursor={"pointer"}
                  />
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>}
      </Stack>
      <HStack pt={"35px"}>
        <Small className="autherlink">
          <>
            {data?.fullname && (
              <>
                {data?.typeCreator && `${data?.typeCreator}: `}
                <Link to={`/generic/${data?.fullname}/research-centre/library`} color="color.blue">
                  {data?.fullname}{" "}
                </Link>
              </>
            )}
            {data?.book && (
              <>
                {bibliography.BOOK + ": "}
                <Link to={`/generic/${data?.book}/research-centre/library`} color="color.blue">
                  {data?.book}{" "}
                </Link>
              </>
            )}
            {data?.publisher && (
              <>
                {bibliography.PUBLISHER + ": "}
                <Link to={`/generic/${data?.publisher}/research-centre/library`} color="color.blue">
                  {data?.publisher}{" "}
                </Link>
              </>
            )}
            {data?.publicationYear && (
              <>
              {data?.publicationYear === +data?.firstEdition || data?.firstEdition===null  ?
              <>
                {bibliography.YEAR_OF_PUBLICATION + ": "}
                <Link to={`/generic/${data?.publicationYear}/research-centre/library`} color="color.blue">
                  {data?.publicationYear}{" "}
                </Link>
                </>
                :<>
                {bibliography.YEAR_OF_PUBLICATION + ": "}
                <Link to={`/generic/${data?.publicationYear}/research-centre/library`} color="color.blue">
                  {`${data?.publicationYear} (F.E : ${+data?.firstEdition})`}
                </Link>
                </>
                }
              </>
            )}
          </>
        </Small>
      </HStack>
    </>
  );
}