const API_BASE_URL = process.env.REACT_APP_MONGODB_URL;

export const signup = (formData) => {
  return fetch(`${API_BASE_URL}users/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error submitting data:", error);
    });
};

export const login = (formData) => {
  return fetch(`${API_BASE_URL}/users/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error submitting data:", error);
    });
};


export const  checkUsernameAvailability = async (username='') => {
  let userName={
    "username": username
  }
  try {
    const response =  await fetch(`${API_BASE_URL}users/isUsernameExist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userName),
  })
  const data = await response.json();
  if (data.message==="Username already exists.") {
    return false;
  }
  return true;
}
catch (error) {
  console.error('Error checking username availability:', error);
  // You can handle API errors here, perhaps by throwing an error to be caught elsewhere
  // return new Yup.ValidationError('There was an error checking the username availability, please try again later.');
}
};

export const  checkUserEmailAvailability = async (email='') => {
  let userEmail={
    "email": email
  }
  try {
    const response =  await fetch(`${API_BASE_URL}users/isEmailExist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userEmail),
  })
  const data = await response.json();
  if (data.message==="Email already exists.") {
    return false;
  }
  return true;
}
catch (error) {
  console.error('Error checking username availability:', error);
  // You can handle API errors here, perhaps by throwing an error to be caught elsewhere
  // return new Yup.ValidationError('There was an error checking the username availability, please try again later.');
}
};