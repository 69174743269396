//Core files
import React, { useEffect, useState } from "react";

//Language files
import { explore } from "../../../constants/constants";


import TabMain from "../../../FunctionalComponents/TabComponents/TabMain";
import Essay from "./Essay";
import { Exhibits } from "./Exhibits";
import { useLocation, useParams } from "react-router-dom";

function ExhibitionCatlouge({masterlistType, masterlist, artAlias, innerTab}) {
  const [visible, setVisible] = React.useState(true);

  const { childTab } = useParams();
  const [attr, setAttr] = useState(0)

  const pathToIndex = {
    'essays': 0,
    'exhibits': 1
  };
  const location = useLocation();
  // const width = { base: "100%", md: "1160px" };

  useEffect(() => {
    setAttr(pathToIndex[childTab] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);

  function formatData() {
    return [
      {
        name: `${explore.ESSAYS}`,
        link:`/object-book-jou/art-heritage-annual/agraphy/body-of-work/${artAlias}/${innerTab}/essays`,
        component: childTab === "essays" && <Essay masterlist={masterlist} masterlistType={masterlistType} artAlias={artAlias} innerTab={innerTab} visible={visible}/>,
      },
      {
        name: `${explore.EXHIBITS}`,
        link:`/object-book-jou/art-heritage-annual/agraphy/body-of-work/${artAlias}/${innerTab}/exhibits`,
        component: childTab === "exhibits" && <Exhibits masterlist={masterlist} masterlistType={masterlistType} artAlias={artAlias} innerTab={innerTab} visible={visible}/>,
      }
    ];
}

const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(masterlist){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist, childTab])

return (
  <TabMain  paddingTop="10px" gap="50px" width={"1080px"} cla pos={"fixed"}
  panelTop={{ base: "65px", sm: "85px", md: "65px" }} topTogleIcon="25px" listId={"listgridbutton6"} data={tabPanelData}  setVisible={setVisible} visible={visible} attr={attr}/>
);
}

export default ExhibitionCatlouge;