import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

//Core files
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCreative,
  EffectFade,
  EffectFlip,
  Navigation,
  Pagination,
} from "swiper";
import { isArray } from "lodash";

//Component files
import H3 from "../../../components/layouts/Typography/H3";
import { common } from "../../../constants/constants";
import { useState } from "react";
import Medium from "../../../Theme/Typography/Medium";
import H4 from "../../../Theme/Typography/H4";
import MediumBold from "../../../Theme/Typography/MediumBold";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

export const FullScreenImage = ({
  isOpen,
  onClose,
  clickedImageIndex,
  data,
  setReadMoreFlag,
  bannerFlag,
  title,
}) => {

  const [expanded, setExpanded] = useState(false);
  const MAX_LENGTH = 600; // Maximum length of description to show when collapsed

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Container maxW={{ base: "auto", md: "1400px" }} px="0px">
      <Modal
        size={"full"}
        isCentered
        isOpen={isOpen}
        onClick={onClose}
        Id="modalfullscreen"
        motionPreset="none"
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent zIndex={"999"}>
          <ModalBody margin={{ base: "0", md: "16" }}>
            <Box
              bg={"white"}
              justifyContent={"center"}
              position={"relative"}
              display={"flex"}
            >
              <Stack maxW={{ base: "100%", md: "1400px" }} position={"relative"}>
                <Swiper
                  effect={"creative"}
                  slidesPerView={1}
                  navigation
                  initialSlide={clickedImageIndex}
                  id="allsliderskk1"
                  modules={[EffectCreative, EffectFade, Navigation, Pagination]}
                >
                  {data && data?.length > 0 &&
                    data?.map((item, index) => {
                      return (
                        <SwiperSlide key={index + 1}>
                          <Stack
                            maxW={"1230px"}
                            marginTop={{ base: "250px", md: "0" }}
                            flexDirection={{ base: "column", md: "row" }}
                            className="row"
                            alignItems={"flex-start"}
                            gap={"0"}
                          // overflowY={{ base: "scroll", md: "hidden" }}
                          >
                            <HStack
                              className="col-sm-12 col-md-7"
                              width={"850px"}
                              justifyContent={"center"}
                              border={"0.01rem solid #d7d7d7"}
                              borderRadius={"5px"}
                              background={"white"}
                              height={title === "ArtworksCatalogues" && "690px"}
                              alignItems={title === "ArtworksCatalogues" && "flex-start"}
                            >
                              <Stack m={title === "ArtworksCatalogues" ? "none" : "8"}>
                                <Box
                                  display={"block"}
                                  justifyContent={"center"}
                                  width={"100%"}
                                  height={{ base: "auto", md: "570px" }}
                                >
                                  {isArray(item?.fullImg) &&
                                    item?.fullImg.length > 0 ? (
                                    <Swiper
                                      grabCursor={true}
                                      navigation={true}
                                      modules={[
                                        EffectFlip,
                                        Navigation,
                                        Pagination,
                                      ]}
                                      id="fullscreenimgslider"
                                    >
                                      {item?.fullImg?.map((it, ind) => (
                                        <>
                                          <SwiperSlide key={ind + 1}>
                                            <VStack>
                                              <Stack
                                                justifyContent={"center"}
                                                width={{
                                                  base: "auto",
                                                  md: "100%",
                                                }}
                                                height={{
                                                  base: "275px",
                                                  md: "100%",
                                                }}
                                              >
                                                <Image
                                                  src={
                                                    it?.image
                                                  }
                                                  width={"100%"}
                                                  height={"inherit"}
                                                  objectFit={"contain"}
                                                  alt=""
                                                />
                                              </Stack>
                                              <HStack>
                                                <H4
                                                  fontSize={"16px"}
                                                  color={"color.blue"}
                                                  lineHeight={"18.78px"}
                                                >
                                                  {`${it?.value} | ${it?.imageName} | ${it?.medium_of_artwork} | ${it?.year}`}
                                                </H4>
                                              </HStack>
                                            </VStack>
                                          </SwiperSlide>
                                        </>
                                      ))}
                                    </Swiper>
                                  ) : (
                                    <>
                                      <Image
                                        src={item?.fullImg}
                                        width={"100%"}
                                        height={"inherit"}
                                        objectFit={"contain"}
                                        alt=""
                                      />
                                      {item?.auction_estimates && (
                                        <HStack flexDirection={"row"}>
                                          {item?.auction_estimates.length > 0 &&
                                            item?.auction_estimates.map(
                                              (it) => (
                                                <Stack pl={"32px"}>
                                                  <Text>{it?.display}</Text>
                                                  <H3>{it?.value_in_inr}</H3>
                                                  <Text>{it?.valuein_dollar}</Text>
                                                </Stack>
                                              )
                                            )}
                                        </HStack>
                                      )}
                                    </>
                                  )}
                                </Box>
                              </Stack>
                            </HStack>
                            <HStack
                              alignItems={"flex-start"}
                              className="col-sm-12 col-md-5"
                              width={"450px"}
                              flexDirection={"column"}
                              pl="30px"
                            >
                              <Box
                                paddingBottom={"10px"}
                                width={"100%"}
                              >
                                  <H4 fontWeight={"700"} 
                                  lineHeight="18.78px"                                 
                                  color={"color.blue"}>
                                  {item?.title}
                                  </H4>
                              </Box>
                              <Stack
                                py="4"
                                marginInlineStart={"0rem!important"}
                                width={"100%"}
                                gap={0}
                              >
                                {item.essayistData?.length > 0 &&
                                  item.essayistData.map((it) => (
                                    <Stack
                                      marginInlineStart={"0rem!important"}
                                      py="1"
                                      gap={"0"}
                                      className="mt-0 px-0"

                                    >
                                      <HStack className="mt-0 px-0">
                                      <MediumBold
                                            lineHeight={"16.44px"}
                                            color="black"
                                          >
                                          {it.display}
                                        </MediumBold>
                                      </HStack>
                                      {it.value && (
                                        <HStack className="mt-0">
                                             <Medium lineHeight={"16.44px"}>
                                            {it.value}
                                          </Medium>
                                        </HStack>
                                      )}
                                    </Stack>
                                  ))}
                              </Stack>
                              {item.auctionData && (
                                <Stack
                                  pb="4"
                                  marginInlineStart={"0rem!important"}
                                  borderBottom={"1px solid #e8e8e8"}
                                  width={"100%"}
                                >
                                  {item.auctionData.length > 0 &&
                                    item.auctionData.map((it) => (
                                      <Stack
                                        marginInlineStart={"0rem!important"}
                                        py="1"
                                        gap={"2"}
                                        className="mt-0 px-0"
                                      >
                                        <HStack className="mt-0 px-0">
                                          <MediumBold
                                            lineHeight={"16.44px"}
                                            color="black"
                                          >
                                            {it.display}
                                          </MediumBold>
                                        </HStack>
                                        {it.value && (
                                          <HStack className="mt-0">
                                            <Medium lineHeight={"16.44px"}>
                                              {it.value}
                                            </Medium>
                                          </HStack>
                                        )}
                                      </Stack>
                                    ))}
                                </Stack>
                              )}
                              {item.content && (
                                <Box
                                py="2"
                                className="ml-0"
                                alignItems={"flex-start"}
                                flexDirection={"column"}
                              >
                                {isArray(item.content) ? (
                                  item.content.map((it) => (
                                    <Stack
                                      marginInlineStart={"0rem!important"}
                                      py="1"
                                      gap={"2"}
                                      className="mt-0 px-0"
                                    >
                                      <HStack className="mt-0 px-0">
                                        <Medium lineHeight={"16.44px"}>
                                          {it.display}
                                        </Medium>
                                      </HStack>
                                      {it.value && (
                                        <HStack className="mt-0">
                                         <Medium lineHeight={"16.44px"}>
                                            {it.value}
                                            </Medium>
                                        </HStack>
                                      )}
                                    </Stack>
                                  ))
                                ) : (
                                  <Box maxH={"380px"} overflowY={"scroll"}>
                                    <>
                                    <Medium lineHeight={"16.44px"}>
                                        {expanded ? item?.content : `${item?.content.substring(0, MAX_LENGTH)}...`}
                                      </Medium>

                                      <Medium
                                        pt="1"
                                        marginInlineStart={"0px!important"}
                                        fontWeight={"700"}
                                        color="color.blue"
                                        cursor={"pointer"}
                                        onClick={toggleExpanded}

                                      >
                                        {expanded ? common.READ_LESS : common.READ_MORE}
                                      </Medium>
                                    </>
                                  </Box>
                                )}
                              </Box>
                              )}
                            </HStack>
                          </Stack>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
                <HStack right={"3%"} top={"1%"} position={"absolute"}>
                  <Image
                    src={closeIcon}
                    onClick={() => {
                      onClose();
                    }}
                    zIndex={99999}
                    cursor={"pointer"}
                  ></Image>
                </HStack>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};
