import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Image,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";

//Service files
import * as getTopAuctionSales from "./../../../services/PersonArtist";
import { CopyPasteService } from "../../../services/CopyPaste";
import { isTransformationNotAllowed } from "../../../util/isTranformationAllowed";

//Component files
import SmallBold from "../../../components/layouts/Typography/SmallBold";
import Small from "../../../components/layouts/Typography/Small";
import { FullScreenImage } from "../Component/FullScreenImage";

//Language files
import { common, explore, filterAttribute } from "../../../constants/constants";
import ImageWithCitation2 from "../../../FunctionalComponents/SimpleGrid/ImageWithCitation2";
import Grid from "../../../FunctionalComponents/SimpleGrid/Grid";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Filter = `${IMAGE_BASE_PATH}/ui/Chevron/filter.png`

export default function TopAuctionSales({ tableName, masterlist }) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [filteroptionData, setFilteroptionsData] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    languages_v: [],
    filmTypes_v: [],
    filmLength_v: [],
    searches_v: [],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [apply, setApply] = useState(false);
  const [FilterData, setFilterData] = useState([{}]);
  const [filterCount, setfilterCount] = useState(0);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1]

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  useEffect(() => {
    const getFilterOptionsData = async () => {
      const result = await getTopAuctionSales.getTopAuctionSalesFilterOptions(
        masterlistType,
        masterlist
      );
      if (result) {
        const dataIntro = result;
        setFilteroptionsData(dataIntro);
      }
    };

    getFilterOptionsData();
  }, [masterlist, masterlistType]);

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
    let selectedArray = [];
    switch (value) {
      case "languages":
        selectedArray = filterOptions.languages_v;
        break;
      case "film_type":
        selectedArray = filterOptions.filmTypes_v;
        break;
      case "film_length":
        selectedArray = filterOptions.filmLength_v;
        break;
      case "searches":
        selectedArray = filterOptions.searches_v;
        break;
      default:
        break;
    }
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
    switch (value) {
      case "languages":
        setFilterOptions({
          ...filterOptions,
          languages_v: selectedArray,
        });
        break;
      case "film_type":
        setFilterOptions({
          ...filterOptions,
          filmTypes_v: selectedArray,
        });
        break;
      case "film_length":
        setFilterOptions({
          ...filterOptions,
          filmLength_v: selectedArray,
        });
        break;
      case "searches":
        setFilterOptions({
          ...filterOptions,
          searches_v: selectedArray,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  // Fetch data function
  const fetchData = async () => {
    let result;

    if (
      Object.values(filterOptions).every((options) => options.length === 0) &&
      searchTerm === ""
    ) {
      // No filters applied, fetch all data
      result = await getTopAuctionSales.getTopAuctionSalesbyMasterlist(
        tableName,
        masterlist
      );
    } else {
      // Filters are applied, fetch filtered data
      result = await getTopAuctionSales.getTopAuctionSalesbyMasterlist(
        tableName,
        masterlist,
        filterOptions,
        searchTerm
      );
    }

    const data = result || []; // Ensure data is not null or undefined
    setFilterData(citationFactor(data));
  };

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      if (apply) {
        fetchData();
      }
      setApply(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const createDataObject = (dataArray) => {
    const dataObject = {};
    if (dataArray.length > 0) {
      const dataItem = dataArray[0];
      for (const key in dataItem) {
        dataObject[key] = dataItem[key];
      }
    }
    return dataObject;
  };
  const citationFactor = (data) => {
    return data.map((item, index) => {
      const artist = createDataObject([item.artist]);
      const medium_artwork = createDataObject([item.medium_artwork]);
      const title_of_artwork = createDataObject([item.title_of_artwork]);

      const lot_number = createDataObject([item?.lot_number]);

      const auction_house = createDataObject([item?.auction_house]);

      const venue_location = createDataObject([item?.venue_location]);

      const date_of_auction = createDataObject([item?.date_of_auction]);

      const type_of_auction = createDataObject([item?.type_of_auction]);

      const number_of_bids_received = createDataObject([
        item?.number_of_bids_received,
      ]);

      const percentage_over_under_mid_estimate = createDataObject([
        item?.percentage_over_under_mid_estimate,
      ]);

      const artwork_square_inch_rate = createDataObject([
        item?.artwork_square_inch_rate,
      ]);

      const previous_auction_sales = createDataObject([
        item?.previous_auction_sales,
      ]);

      const cagr_based_on_previous_sale = createDataObject([
        item?.cagr_based_on_previous_sale,
      ]);
      const content = createDataObject([item?.desc]);
      const estimates = createDataObject([item?.estimates]);
      const winning_bid = createDataObject([item?.winning_bid]);
      const size = createDataObject([item?.size]);

      return {
        image: item?.image,
        artist,
        desc: artist?.full_name ? `${artist?.full_name}. ${title_of_artwork?.value}, ${medium_artwork?.value}, ${medium_artwork?.date_of_artwork} | ${auction_house?.value}, ${venue_location?.value}` : `Caption Awaited`,
        medium_artwork,
        title_of_artwork,
        link: item?.link,
        title: `${artist?.name}`,
        fullImg: item?.fullImage,
        essayistData: [
          title_of_artwork,
          medium_artwork,
          size,
          lot_number,
          auction_house,
          venue_location,
          date_of_auction,
          type_of_auction,
        ],
        auctionData: [
          number_of_bids_received,
          percentage_over_under_mid_estimate,
          artwork_square_inch_rate,
          previous_auction_sales,
          cagr_based_on_previous_sale,
        ],
        auction_estimates: [estimates, winning_bid],
        content: [content],
      };
    });
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const { mockData } =
          await getTopAuctionSales.getTopAuctionSalesbyMasterlist(
            tableName,
            masterlist
          );
        if (mockData && isTransformationNotAllowed() === true) {
          const processedData = citationFactor(mockData);
          setFilterData(processedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(filterCount)

  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed() === true) {
        try {
          const { filterMockData } =
            await getTopAuctionSales.getTopAuctionSalesbyMasterlist(
              tableName,
              masterlist
            );
          const filteredMockDataResult = await filterMockData(
            filterOptions,
            searchTerm
          );
          setFilterData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error("Error filtering data:", error);
        }
        setApply(false);
      }
    }
    fetchData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply, filterOptions, masterlist, searchTerm, tableName]);

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };
  return (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      {!showFilterBox && <FilterButton onClick={handleFilterClick} />}
      <Container
        maxW="auto"
        className="songs"
        pr={{ base: "4", md: "0px" }}
        pl={{ base: "4", md: "40px" }}
        pb="8"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {FilterData.length > 0 ? (
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
              <Grid
                data={FilterData}
                columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                spacing={{ base: "5px", md: "24px" }}
                handleImageClick={handleImageClick}
                component={ImageWithCitation2}
              />
            </HStack>
          ) : (
            <WebpageComingSoon/>
          )}
          <HStack
            marginLeft={"4"}
            marginRight={"0px!important"}
            className="mt-0"
            alignItems={"flex-start"}
          >
            {showFilterBox && (
              <Box className="box-filterSKK">
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <HStack>
                    <SmallBold
                      as="u"
                      color={"black"}
                      // onClick={() => handleClearAll()}
                      cursor="pointer"
                      onClick={() => {
                        setFilterOptions({
                          documentType: [],
                          year: [],
                          creatorType: [],
                          publisher: [],
                        });
                        setSearchTerm("");
                      }}
                    >
                      {explore.RESET}
                      <span style={{ color: "#004F79" }}>
                        {" "}
                        {/* {filterCount > 0 && ` (${filterCount})`} */}
                      </span>
                    </SmallBold>
                  </HStack>
                  <HStack className="mt-0">
                    <RiCloseCircleFill
                      size={"22px"}
                      cursor="pointer"
                      onClick={(e) => setShowFilterBox(false)}
                    />
                  </HStack>
                </Stack>
                <HStack
                  alignItems={"left"}
                  flexDirection={"column"}
                  marginTop={"25px"}
                >
                  {/* <SmallBold py="4">Search</SmallBold> */}
                  <InputGroup className="ml-0" pb="4">
                    <Input
                      placeholder="Search"
                      // value={searchQuery}
                      // onChange={handleSearch}
                      height={"32px"}
                      pb={"4px"}
                      _placeholder={{ fontSize: "12px", fontWeight: "400" }}
                      onChange={handleSearch}
                      value={searchTerm}
                    />
                    <InputRightElement pb={"6px"}>
                      <BiSearch color="green.500" fontSize={"16px"} />
                    </InputRightElement>
                  </InputGroup>
                </HStack>
                <Accordion allowToggle allowMultiple>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                          {filterAttribute.PRICE}
                        </Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small>
                        {filteroptionData.price.length > 0
                          ? filteroptionData.price.map((item) => {
                            return (
                              <Flex
                                borderBottom={"1px"}
                                borderBottomColor={"#f1f1f1"}
                              >
                                <Checkbox
                                  borderColor={"black"}
                                  _checked={{
                                    "& .chakra-checkbox__control": {
                                      background: "black",
                                    },
                                  }}
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                  isChecked={filterOptions.creatorType.includes(
                                    item
                                  )}
                                  onChange={() => {
                                    handleCheckboxChange("creatorType", item);
                                  }}
                                />
                                <Box p="1">
                                  <Text fontSize={"14px"}>{`${item}`}</Text>
                                </Box>
                              </Flex>
                            );
                          })
                          : common.CONTENT_AWAITED}
                      </Small>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                          {filterAttribute.YEAR_OF_ARTWORK}
                        </Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      {filteroptionData.yearOfArtwork.length > 0
                        ? filteroptionData.yearOfArtwork.map((item) => {
                          return (
                            <Flex
                              borderBottom={"1px"}
                              borderBottomColor={"#f1f1f1"}
                            >
                              <Checkbox
                                borderColor={"black"}
                                _checked={{
                                  "& .chakra-checkbox__control": {
                                    background: "black",
                                  },
                                }}
                                iconColor="white"
                                cursor="pointer"
                                marginRight={"5px"}
                                isChecked={filterOptions.documentType.includes(
                                  item
                                )}
                                onChange={() => {
                                  handleCheckboxChange("docType", item);
                                }}
                              />
                              <Box p="1">
                                <Text fontSize={"14px"}>
                                  {`Book.${item}`}
                                </Text>
                              </Box>
                            </Flex>
                          );
                        })
                        : common.CONTENT_AWAITED}
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                          {filterAttribute.YEAR_OF_SALE}
                        </Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <Box maxH="130px" overflowY="auto">
                      <AccordionPanel pb={4} className="px-1">
                        <Small>
                          {filteroptionData.yearOfSale.length > 0
                            ? filteroptionData.yearOfSale.map((item) => {
                              return (
                                <Flex
                                  borderBottom={"1px"}
                                  borderBottomColor={"#f1f1f1"}
                                >
                                  <Checkbox
                                    borderColor={"black"}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "black",
                                      },
                                    }}
                                    iconColor="white"
                                    cursor="pointer"
                                    marginRight={"5px"}
                                    isChecked={filterOptions.year.includes(
                                      item
                                    )}
                                    onChange={() => {
                                      handleCheckboxChange("year", item);
                                    }}
                                  />
                                  <Box p="1">
                                    <Text fontSize={"14px"}>{`${item}`}</Text>
                                  </Box>
                                </Flex>
                              );
                            })
                            : common.CONTENT_AWAITED}
                        </Small>
                      </AccordionPanel>
                    </Box>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                          {filterAttribute.MEDIUM}
                        </Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <Box maxH="130px" overflowY="auto">
                      <AccordionPanel pb={4} className="px-1">
                        <Small>
                          {filteroptionData.medium.length > 0
                            ? filteroptionData.medium.map((item) => {
                              return (
                                <Flex
                                  borderBottom={"1px"}
                                  borderBottomColor={"#f1f1f1"}
                                >
                                  <Checkbox
                                    borderColor={"black"}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "black",
                                      },
                                    }}
                                    iconColor="white"
                                    cursor="pointer"
                                    marginRight={"5px"}
                                    isChecked={filterOptions.year.includes(
                                      item
                                    )}
                                    onChange={() => {
                                      handleCheckboxChange("year", item);
                                    }}
                                  />
                                  <Box p="1">
                                    <Text fontSize={"14px"}>{`${item}`}</Text>
                                  </Box>
                                </Flex>
                              );
                            })
                            : common.CONTENT_AWAITED}
                        </Small>
                      </AccordionPanel>
                    </Box>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                          {filterAttribute.VENUE}
                        </Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <Box maxH="130px" overflowY="auto">
                      <AccordionPanel pb={4} className="px-1">
                        <Small>
                          {filteroptionData.venueLocation.length > 0
                            ? filteroptionData.venueLocation.map((item) => {
                              return (
                                <Flex
                                  borderBottom={"1px"}
                                  borderBottomColor={"#f1f1f1"}
                                >
                                  <Checkbox
                                    borderColor={"black"}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "black",
                                      },
                                    }}
                                    iconColor="white"
                                    cursor="pointer"
                                    marginRight={"5px"}
                                    isChecked={filterOptions.year.includes(
                                      item
                                    )}
                                    onChange={() => {
                                      handleCheckboxChange("year", item);
                                    }}
                                  />
                                  <Box p="1">
                                    <Text fontSize={"14px"}>{`${item}`}</Text>
                                  </Box>
                                </Flex>
                              );
                            })
                            : common.CONTENT_AWAITED}
                        </Small>
                      </AccordionPanel>
                    </Box>
                  </AccordionItem>
                </Accordion>
                <HStack marginTop={"10px"}>
                  <Button
                    color={"#ffffff"}
                    onClick={() => {
                      //   setApply((prev) => !prev);
                      setShowFilterBox(false);
                    }}
                    width={"199px"}
                    height={"36px"}
                    bg={"#000000"}
                    fontSize={"16px"}
                    fontWeight={"400"}
                    variant={"unstyled"}
                  >
                    {explore.APPLY}
                  </Button>
                </HStack>
              </Box>
            )}
          </HStack>
        </Stack>
      </Container>
      {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={FilterData.slice(0, 1)}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="TopAuctionSales"
        />
      )}
    </Stack>
  );
}

export const FilterButton = ({ onClick, marginRight, paddingTop }) => {
  return (
    <>
      <Stack
        position={"relative"}
        pos={"fixed"}
        marginTop={"100px"}
        marginRight={marginRight}
        paddingTop={paddingTop}
        zIndex={"99!important"}
        display={{ base: "none", md: "flex" }}
      >
        <Stack
          id="filterstickywbtn"
          marginRight={"0px!important"}
          onClick={onClick}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap="10px"
          >
            <Image transform={"rotate(360deg)"} src={Filter} />
            <Text
              as="button"
              transform={"rotate(90deg)"}
              fontSize={"14px"}
              fontWeight={"700"}
              marginTop={"4px"}
            >
              {explore.FILTER}
            </Text>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
