import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Box, HStack, Image, Stack } from '@chakra-ui/react';
import { FaUserAlt } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { AiOutlinePicture } from '@react-icons/all-files/ai/AiOutlinePicture';
import H4 from '../../Theme/Typography/H4';
import Small from '../../Theme/Typography/Small';
import { IoTriangleOutline } from 'react-icons/io5';
import { explore } from '../../constants/constants';

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const pplImg = `${IMAGE_BASE_PATH}/ui/Chevron/people.png`

const TimelineWithUserIocn = ({ data, handleBooklet, setOpenDrawer,handleDrawer, setCatalogue, showImage, marginBottom="15px" }) => {
    const { alias } = useParams();
    const masterlist = alias;
    const currentEndpoint = window.location.pathname;
    const parts = currentEndpoint.split("/");
    const masterlistType = parts[1];

    return (
        <>
        {data && data?.map((item, index) => (
        <Stack flexDirection={"row"} key={index}>
            <Box marginTop={"21px"} marginRight={"8px"}>
            {item?.image ? (
                <AiOutlinePicture
                    onClick={() => showImage(index)}
                    cursor={"pointer"}
                    color="#1E90FF"
                    size={16}
                />
            ) : (
                <Box width={"16px"} height={"16px"}></Box>
            )}
            </Box>
        <VerticalTimeline
            layout={"1-column-left"}
            className="layout"
        >
                 <VerticalTimelineElement
                    id="satyajit_timeline"
                    contentStyle={{ fontSize: "sm" }}
                    iconStyle={{
                        background: "#fff",
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight:"18.78px"
                    }}
                    icon={item.year}
                >
                    <Stack
                        p="0"
                        flexDirection={"inherit"}
                        flexWrap="wrap"
                        gap="1"
                    >
                        <HStack marginBottom={marginBottom}>
                            {item.link ? (
                                <H4
                                   color="color.blue"
                                    m="0"
                                    lineHeight={"18.78px"}
                                    width={{ base: "100%", md: "650px" }}
                                    onClick={() => {
                                        handleDrawer(item)
                                    }}
                                    cursor={"pointer"}
                                >
                                    {item?.group && item?.group === 'Group' ? (
                                        <Image
                                            src={pplImg}
                                            display="inline"
                                            marginRight="8px"
                                        />
                                    ) :item?.group === 'Triennale'?
                                    (
                                        <IoTriangleOutline
                                            color="black"
                                            display="inline"
                                            fontSize="11px"
                                        />
                                    )
                                    : (
                                        <FaUserAlt
                                            color="black"
                                            display="inline"
                                            fontSize="11px"
                                        />
                                    )}
                                    {`${item?.desc} `}
                                </H4>
                                ) : (
                                <H4
                                    color="color.blue"
                                    cursor={"pointer"}
                                    m="0"
                                    lineHeight={"18.78px"}
                                    width={{ base: "100%", md: "650px" }}
                                    onClick={() => {
                                        handleDrawer(item)
                                    }}
                                >
                                    { item?.group && item?.group === 'Group' ? (
                                        <Image
                                            src={pplImg}
                                            display="inline"
                                            marginRight="8px"
                                        />
                                    ):item?.group === 'Triennale'?
                                    (
                                        <IoTriangleOutline
                                            color="black"
                                            display="inline"
                                            fontSize="16px"
                                        />
                                    ) : (
                                        <FaUserAlt
                                            color="black"
                                            display="inline"
                                            fontSize="11px"
                                        />
                                    )}
                                    {`${item?.desc} `}
                                </H4>
                            )}
                        </HStack>
                        <HStack mt={"0px !important"}>
                            {item.catalogue ? (
                                <Link to={`/${masterlistType}/${masterlist}/body-of-work/${item.catalogue.toLowerCase()}`}>
                                    <Small
                                        color="color.primary"
                                        mt="0"
                                        width={{ base: "100%", md: "650px" }}
                                        cursor={"pointer"}
                                        onClick={() => setCatalogue(item)}
                                    >
                                        {" "}
                                        {`${item?.shortdesc} `}
                                        <span
                                            style={{
                                                color: "color.blue",
                                                fontWeight: 700
                                            }}
                                        >{`${item?.catalogue ? item?.catalogue : ""
                                            }`}</span>
                                    </Small>
                                </Link>
                            ) : (
                                <>
                                {handleBooklet && (
                                    <Small
                                    color="color.primary"
                                     mt="0"
                                     width={{ base: "100%", md: "650px" }}
                                 >
                                    {item?.shortdesc}. {" "}
                                    {item && (
                                    <>
                                        {item?.essaysCount > 0 && (
                                        <>
                                            <Small
                                            as="span"
                                            onClick={() => handleBooklet(item.alias, 'essays')}
                                            color="color.blue"
                                            cursor="pointer"
                                            >
                                            {explore.ESSAYS}
                                            </Small>
                                            {item?.exhibitsCount > 0 && ' | '}
                                        </>
                                        )}
                                        {item?.exhibitsCount > 0 && (
                                        <Small
                                            as="span"
                                            onClick={() => handleBooklet(item.alias, 'exhibits')}
                                            color="color.blue"
                                            cursor="pointer"
                                        >
                                            {explore.EXHIBITS}
                                        </Small>
                                        )}
                                    </>
                                    )}
                                 </Small>
                                )}
                                </>
                            )}
                        </HStack>
                    </Stack>
                </VerticalTimelineElement>
        </VerticalTimeline>
        </Stack>
        ))}
        </>
    );
};

export default TimelineWithUserIocn;
