import {
  HStack,
  Stack,
  IconButton,
  Image,
} from "@chakra-ui/react";
//Core file
import React, { useState, useEffect, useRef } from "react";
import * as getEditionsData from "../../services/Editions";
import "react-vertical-timeline-component/style.min.css";
import { BsGrid } from "@react-icons/all-files/bs/BsGrid";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";

//Component file
import GridView from "./BodyOfWork/GridView";
import TabularView from "./BodyOfWork/TabularView";
import { totalFilterCount, updateFacetsData } from "../../util/helper";
import { useNavigate } from "react-router-dom";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;


export const AnnualEditions = ({masterlist, masterlistType, setContentImage}) => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState({ search: "", filters: {}, sortBy_v: "" });
  const [apply, setApply] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = React.useState(true); 
  const [filterPills, setFilterPills] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches", e.target.value)
  };

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }

    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }

    if (selectedString !== type) {
      selectedString = type;
    }

    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };

  const citationFactor = (data) => {
    // const getValue = (item, defaultValue = "") => item?.value || defaultValue;
  
    return data.map((item) => {

      return {
        image: item?.direct_url_of_cover_image,
        contentImage: item?.direct_url_of_content_image,
        desc: item?.bibliographic_citation,
        listDesc: item?.bibliographic_caption,
        alias: item?.alias,
        year: item?.sortable_year
      };
    });
  };

  const [facets, setFacets] = useState([]);
  const [sort, setSort] = useState([]);
  const initialLoadComplete = useRef(false);

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getEditionsData.editionsData(masterlistType, masterlist, filterOptions, page);
      setLoading(false);
      if (result && result?.data.length > 0) {
        const processedData = citationFactor(result?.data);

        setData(processedData);
        if (!initialLoadComplete.current) {
          setPage((prevPage) => prevPage + 1);
          initialLoadComplete.current = true;
        }
        if (result?.total <= result?.data.length) {
          setHasMore(false)
        }

        if (result?.facetsHeaders) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
          if (formattedFacets && formattedFacets.length > 0) {
            setFilterOptions(prevFilterOptions => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                ...formattedFacets.reduce((acc, facet) => {
                  acc[facet.key] = [];
                  return acc;
                }, {})
              }
            }));
          }
        }
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {

    fetchData();
    setFilterPills(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = async () => {
    try {
      const result = await getEditionsData.editionsData(masterlistType, masterlist, filterOptions, page);

      if (result) {

        const processedData = citationFactor(result?.data);
        setData(prevData => [...prevData, ...processedData]);
        setPage(prevPage => prevPage + 1);
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);

        if (data && data.length >= result?.total) {
          setHasMore(false);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function fetchFilterData(page = 1) {

    try {
      const result = await getEditionsData.editionsData(masterlistType, masterlist, filterOptions, page);

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        if (data && data.length >= result?.total) {
          setHasMore(false);
        }
        const filtercnt= totalFilterCount(filterOptions?.filters)
        
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {

    if (apply) {
      setApply(false);
      fetchFilterData();
      setFilterPills(filterOptions)
      const filtercnt = totalFilterCount(filterOptions?.filters)
    setFilterCount(filtercnt)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const handleReset = () => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setFilterCount(0)
    setSearchTerm("");
  }

  const handleLinkClick = (item) => {
    setContentImage(item?.contentImage)
    navigate(item?.alias)
  }

  return (
    <Stack position={"relative"}>
      <Stack
        zIndex={99}
        position={"sticky"}
        top={{ base: "124px", md: "85px" }}
        marginTop={"-60px"}
        marginLeft={"auto"}
        marginRight={"55px"}
        flexDirection="row"
        justifyContent={"flex-end"}
        id="listgridviewbtn"
        gap={0}
        bg="white"
      >
        <HStack marginTop={"0px!important"}>
          <IconButton
            variant="unstyled"
            onClick={() => setVisible(false)}
            color={!visible ? "black" : "#989898"}
            fontSize="20px"
            display="flex"
            borderTopLeftRadius="4px"
            borderBottomLeftRadius="4px"
            borderTopRightRadius="0px"
            borderBottomRightRadius="0px"
            border="1px"
            borderColor={"#989898"}
            height="32px"
          >
            <RiMenuLine ml="4" />
          </IconButton>
        </HStack>
        <HStack marginTop={"0px!important"}>
          <IconButton
            variant="unstyled"
            onClick={() => setVisible(true)}
            color={visible ? "black" : "#989898"}
            fontSize="16px"
            display="flex"
            borderTopLeftRadius="0px"
            borderBottomLeftRadius="0px"
            borderTopRightRadius="4px"
            borderBottomRightRadius="4px"
            border="1px"
            borderColor={"#989898"}
            height="32px"
          >
            <BsGrid />
          </IconButton>
        </HStack>
      </Stack>
     {!loading ?
      <>
      {visible ? (
        <GridView
        data={data}
        fetchMoreData={fetchMoreData}
        hasMore={hasMore}
        setApply={setApply}
        apply={apply}
        setShowFilterBox={setShowFilterBox}
        handleFilterClick={handleFilterClick}
        showFilterBox={showFilterBox}
        handleCheckboxChange={handleCheckboxChange}
        handleSearch={handleSearch}
        filterOptions={filterOptions}
        filteroptionData={facets}
        setFilterOptions={setFilterOptions}
        setSearchTerm={setSearchTerm}
        filterCount={filterCount}
        searchTerm={searchTerm}
        handleReset={handleReset}
        handleImageClick={handleLinkClick}
        sort={sort}
        setFilterPills={setFilterPills}
        filterPills={filterPills}
        filteredResultCount={filteredResultCount}
        />
      ) : (
        <TabularView 
        data={data}
        fetchMoreData={fetchMoreData}
        hasMore={hasMore}
        setApply={setApply}
        apply={apply}
        setShowFilterBox={setShowFilterBox}
        handleFilterClick={handleFilterClick}
        showFilterBox={showFilterBox}
        handleCheckboxChange={handleCheckboxChange}
        handleSearch={handleSearch}
        filterOptions={filterOptions}
        filteroptionData={facets}
        setFilterOptions={setFilterOptions}
        setSearchTerm={setSearchTerm}
        filterCount={filterCount}
        searchTerm={searchTerm}
        handleReset={handleReset}
        handleImageClick={handleLinkClick}
        sort={sort}
        setFilterPills={setFilterPills}
        filterPills={filterPills}
        filteredResultCount={filteredResultCount}
        />
      )}
      </> :
      <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} >
      <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" mt={12} />
    </HStack>
      }
    </Stack>
  );
}