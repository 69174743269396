import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCreative,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { isArray } from "lodash";

//Component files
import H3 from "../../components/layouts/Typography/H3";
import H2 from "../../Theme/Typography/H2";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

export const FullScreenImage = ({
  isOpen,
  onClose,
  clickedImageIndex,
  data,
  setReadMoreFlag,
  bannerFlag,
  title,
  imageData,
}) => {
  return (
    <Container maxW={"1400px"} px="0px" >
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
      motionPreset="none"
      autoFocus={false}
      returnFocusOnClose={false} 
    >
      <ModalOverlay />
      <ModalContent zIndex={"999"}>
        <ModalBody margin={{ base: "0", md: "16" }}>
          <Box
            bg={"white"}
            justifyContent={"center"}
            position={"relative"}
            display={"flex"}
          >
            <Stack   maxW={{base:"100%",md:"1400px"}}
              ml={{base:"0px",md:"12"}}
              marginTop={{ base: "250px", md: "0" }}
              flexDirection={{ base: "column", md: "row" }}
              alignItems={"flex-start"}
              gap={{base:"0",md:"8"}}>
                <Swiper
                  effect={{ sm: "fade", md: "creative" }}
                  slidesPerView={1}
                  navigation
                  initialSlide={data.findIndex((item) => item?.image===''?item?.imagePath === imageData:item?.image === imageData)}
                  id="allsliderskk1"
                  modules={[EffectCreative, EffectFade, Navigation, Pagination]}
                >
                  {data && data?.length > 0 &&
                    data?.map((item, index) => {
                      return (
                        <SwiperSlide key={index + 1}>
                          <Stack
                            maxW={"1230px"}
                            marginTop={{ base: "250px", md: "0" }}
                            flexDirection={{ base: "column", md: "row" }}
                            className="row"
                            alignItems={"flex-start"}
                            gap={"35px"}
                          >
                            <HStack
                             width={{base:"auto",md:"650px"}}
                             justifyContent={"center"}
                             border={"0.01rem solid #d7d7d7"}
                             borderRadius={"5px"}
                            >
                              <Stack mb={4}>
                                <Box
                                    display={"block"}
                                    justifyContent={"center"}
                                    width={"100%"}
                                    height={{ base: "auto", md: "550px" }}
                                >
                                    <>
                                      <Image
                                        src={item?.image!==''?item?.image:item?.imagePath}
                                        width={"100%"}
                                        height={"inherit"}
                                        objectFit={"contain"}
                                        alt=""
                                      />
                                      {item?.auction_estimates && (
                                        <HStack flexDirection={"row"} pt={4}>
                                          {item?.auction_estimates.length > 0 &&
                                            item?.auction_estimates.map(
                                              (it) => (
                                                <Stack pl={"32px"}>
                                                  <Text>{it?.display}</Text>
                                                  <H3>{it?.value_in_inr}</H3>
                                                  <Text>{it?.valuein_dollar}</Text>
                                                </Stack>
                                              )
                                            )}
                                        </HStack>
                                      )}
                                    </>
                                </Box>
                              </Stack>
                            </HStack>
                            <HStack
                               alignItems={"flex-start"}
                               width={{base:"auto",md:"500px"}}
                               flexDirection={"column"}
                            >
                              {item?.fullImageTitle && <Box
                                paddingBottom={"10px"}
                                width={"100%"}
                                borderBottom={"1px"}
                                borderColor={"#e8e8e8"}
                              >
                                <H2
                                  fontWeight={"700"}
                                  color={"color.blue"}
                                >
                                  {item?.fullImageTitle}
                                </H2>
                              </Box>}
                              {/* Need for future use */}
                              <Stack
                                pb="4"
                                marginInlineStart={"0rem!important"}
                                borderBottom={"1px solid #e8e8e8"}
                                width={"100%"}
                                gap={0}
                              >
                                 {item?.essayistData && item?.essayistData.length > 0 &&
                                  item?.essayistData.map((it) => (
                                    <Stack
                                      marginInlineStart={"0rem!important"}
                                      py="1"
                                      gap={"0"}
                                      className="mt-0 px-0"
                                      flexDirection={"column"}

                                    >
                                      <HStack className="mt-0 px-0">
                                        <Text
                                          fontSize={"14px"}
                                          fontWeight={"700"}
                                          color="black"
                                        >
                                          {it?.display && it?.display}
                                        </Text>
                                      </HStack>
                                      {it?.value && (
                                        <HStack className="mt-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"400"}
                                            // color="#035DA1"
                                          >
                                            {`${it?.value}`}
                                          </Text>
                                        </HStack>
                                      )}
                                    </Stack>
                                  ))} 
                              </Stack>
                              {/* {item?.auctionData && (
                                <Stack
                                  pb="4"
                                  marginInlineStart={"0rem!important"}
                                  borderBottom={"1px solid #e8e8e8"}
                                  width={"100%"}
                                >
                                  {item?.auctionData.length > 0 &&
                                    item?.auctionData.map((it) => (
                                      <Stack
                                        marginInlineStart={"0rem!important"}
                                        py="1"
                                        gap={"2"}
                                        className="mt-0 px-0"
                                      >
                                        <HStack className="mt-0 px-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"700"}
                                            color="black"
                                          >
                                            {it.display}
                                          </Text>
                                        </HStack>
                                        {it.value && (
                                          <HStack className="mt-0">
                                            <Text
                                              fontSize={"14px"}
                                              fontWeight={"400"}
                                              // color="#035DA1"
                                            >
                                              {it.value}
                                            </Text>
                                          </HStack>
                                        )}
                                      </Stack>
                                    ))}
                                </Stack>
                              )} */}
                              <Box
                                py="2"
                                className="ml-0"
                                alignItems={"flex-start"}
                                flexDirection={"column"}
                              >
                                {isArray(item?.content) ? (
                                  item?.content.map((it) => (
                                    <Stack
                                      marginInlineStart={"0rem!important"}
                                      py="1"
                                      gap={"2"}
                                      className="mt-0 px-0"
                                    >
                                      <HStack className="mt-0 px-0">
                                        <Text
                                          fontSize={"14px"}
                                          fontWeight={"700"}
                                          color="black"
                                        >
                                          {it.display}
                                        </Text>
                                      </HStack>
                                      {it.value && (
                                        <HStack className="mt-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"400"}
                                          >
                                            {it.value}
                                          </Text>
                                        </HStack>
                                      )}
                                    </Stack>
                                  ))
                                ) : (
                                  <Text>{item?.content}</Text>
                                )}
                              </Box>
                            </HStack>
                          </Stack>
                          <HStack>
                         <Image
                          right={"3.5%"} top={"3%"} position={"absolute"}
                          src={closeIcon}
                          onClick={() => {
                          onClose();}}
                          cursor={"pointer"}/>
                         </HStack>    
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      </Container>
  );
};