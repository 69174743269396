import {
  Box,
  Container,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import React from "react";
import { TbFoldersOff } from "react-icons/tb";

//Service files
import { CopyPasteService } from "../../services/CopyPaste";

//Component files
import { FilterButton } from "../../CommonComponents/FilterButton";
// import CommonDrawer from "../../Film/BodyOfWork/Components/CommonDrawer";
import TimeLine from "../../FunctionalComponents/TimeLine/TimeLine";
import TimelineWithSubText from "../../FunctionalComponents/TimeLine/TimelineWithSubText";

//Language files
import { common, explore } from "../../constants/constants";
import { FilterBox } from "../../FunctionalComponents/Filter/FilterBox";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../CommonComponents/NoMoreToLoad";
import CommonDrawer from "../../pages/Film/BodyOfWork/Components/CommonDrawer";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

function TabularView({ 
  masterlistType,
  masterlist,
  data,
  showFilterBox,
  handleFilterClick,
  handleCheckboxChange,
  handleSearch,
  filterOptions,
  filteroptionData,
  setFilterOptions,
  setSearchTerm,
  filterCount,
  searchTerm,
  handleReset,
  fetchMoreData,
  hasMore,
  setShowFilterBox,
  setApply,
  sort,
  loading,
  handleBooklet,
  setOpenDrawer,
  handleDrawer,
  drawerBook,
  openDrawer,
  setFilterPills,
  filterPills,
}) {

  return (
    <>
      {data && data?.length !== 0 ? (
        <Stack flexDirection={"row-reverse"} justifyContent="space-between" mt={0}>
          {!showFilterBox && (
            <FilterButton onClick={handleFilterClick} marginRight={"-56px"} />
          )}
          {data && data?.length !== 0 ? (
            <Container
              position={"relative"}
              maxW="auto"
              px="0px"
              className="songs"
              paddingTop={{ base: "40px", md: "0px" }}
            >
              <Stack
                flexDirection={{ base: "column", md: "row" }}
                justifyContent="space-between"
                gap={4}
                minH={{ base: "100vh", md: "82vh" }}
              >
                <HStack
                  flexDirection={"column"}
                  flex="1"
                  alignItems={"flex-start"}
                  onCopy={(e) => CopyPasteService(e, window.location.href)}
                >
                  {drawerBook &&<CommonDrawer
                    setDrawerOpen={setOpenDrawer}
                    drawerOpen={openDrawer}
                    data={drawerBook}
                  />}
                  {data.length > 0 ?
                    <InfiniteScroll
                      dataLength={data.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<Box textAlign="center" marginLeft={"50%"}>
                        <Stack marginTop={"20px"}>
                          <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                        </Stack>
                      </Box>}
                      endMessage={<NoMoreToLoad/>}
                    >
                      <TimeLine
                        handleBooklet={handleBooklet}
                        data={data}
                        setOpenDrawer={setOpenDrawer}
                        component={TimelineWithSubText}
                        handleDrawer={handleDrawer}
                      />
                    </InfiniteScroll>
                    : (
                      <HStack w={"100%"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          w={"inherit"}
                          justifyContent={"right"}
                          gap={4}
                        >
                          <TbFoldersOff size={40} />
                          <Text
                            py={"40"}
                            fontSize={"30px"}
                            fontWeight="700"
                            textAlign="center"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            {common.NO_DATA}
                          </Text>
                        </Box>
                      </HStack>
                    )}
                </HStack>
                <HStack
                  alignItems={"flex-start"}
                  marginRight={"-56px!important"}
                  marginTop={"0px!important"}
                >
                  {showFilterBox && (
                    <FilterBox
                      setShowFilterBox={setShowFilterBox}
                      setApply={setApply}
                      explore={explore}
                      sort={sort}
                      handleCheckboxChange={handleCheckboxChange}
                      handleSearch={handleSearch}
                      filterOptions={filterOptions}
                      filteroptionData={filteroptionData}
                      setFilterOptions={setFilterOptions}
                      setSearchTerm={setSearchTerm}
                      filterCount={filterCount}
                      searchTerm={searchTerm}
                      handleReset={handleReset}
                      className="common-box-filter"
                    />
                  )}
                </HStack>
              </Stack>
            </Container>
          ) : (
            <Container
              position={"relative"}
              maxW="auto"
              px="0px"
              className="songs"
              mt={"0px !important"}
            >
              {" "}
              <Stack
                flexDirection={"row"}
                justifyContent="space-between"
                height={"100vh"}
                gap={4}
              >
                <HStack
                  flexDirection={"column"}
                  flex="1"
                  onCopy={(e) => CopyPasteService(e, window.location.href)}
                >
                  {" "}
                  <Text
                    py={"40"}
                    fontSize={"45px"}
                    fontWeight="700"
                    textAlign="center"
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <TbFoldersOff style={{ marginRight: "20px" }} />
                    {common.NO_DATA}
                  </Text>
                </HStack>
                <HStack
                  alignItems={"flex-start"}
                  marginRight={"-40px!important"}
                  marginTop={"0px!important"}
                >
                  {showFilterBox && (
                    <FilterBox
                      setShowFilterBox={setShowFilterBox}
                      setApply={setApply}
                      explore={explore}
                      handleCheckboxChange={handleCheckboxChange}
                      handleSearch={handleSearch}
                      filterOptions={filterOptions}
                      filteroptionData={filteroptionData}
                      setFilterOptions={setFilterOptions}
                      setSearchTerm={setSearchTerm}
                      filterCount={filterCount}
                      searchTerm={searchTerm}
                      handleReset={handleReset}
                      className="common-box-filter"
                    />
                  )}
                </HStack>
              </Stack>
            </Container>
          )}
        </Stack>
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.LOADING}
          </Text>
        </Stack>
      )}
    </>
  );
}
export default TabularView;
