import React, { useState } from "react";
import {
  Box,
  useDisclosure,
  Alert,
  CloseButton,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Layout from "../../components/sections/SidebarLayout";
import StepIndicator from "./StepIndicator";
import { signup } from "../../services/User";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const Register = () => {
  const [step, setStep] = useState(1);
  const username="nevilletuli"+_.random(1000,9999)
  const [formData, setFormData] = useState({
    firstName: "Neville",
    lastName: "Tuli",
    username: username,
    email:username+"@tuliresearchcentre.org",
    age: "More than 50",
    nationality: "Indian",
    education1: "Doc.",
    education2: "",
    education3: "",
    password: "Neville1234",
    confirmPassword: "Neville1234",
    masterlist: [
      "aruna-vasudev",
      "amitabh-bachchan",
      "akira-kurosawa",
      "bimal-roy",
      "chidananda-dasgupta",
      "charlie-chaplin",
      "dilip-kumar",
      "fritz-lang",
      "kamal-amrohi",
      "k-a-abbas",
      "leonard-schrader-collection-of-vintage-hollywood-lobby-cards",
      "mrinal-sen",
      "manmohan-desai",
      "metropolis-1927",
      "mera-naam-joker-1970",
      "mughal-e-azam-1960",
      "mapin-publishing-house",
      "neville-tuli",
      "naseerudin-shah",
      "osian-s-cinefan-festival-of-asian-and-arab-cinema",
      "bara-imambara-at-lucknow",
      "chittaprosad-bhattacharya",
      "ranthambore",
      "r-r-bharadwaj",
      "art-heritage-annual",
      "ebrahim-alkazi",
      "himalaya",
      "geeta-kapur",
      "sayed-haider-raza",
      "somnath-hore",
      "shatranj-ke-khilari-1977",
      "song-synopsis-booklets",
    ],
    core: {
      alias: "cinema-as-a-critical-educational-resource-1",
      masterlists: [
        "aruna-vasudev",
        "amitabh-bachchan",
        "akira-kurosawa",
        "bimal-roy",
        "chidananda-dasgupta",
        "charlie-chaplin",
        "dilip-kumar",
        "fritz-lang",
        "kamal-amrohi",
        "k-a-abbas",
        "leonard-schrader-collection-of-vintage-hollywood-lobby-cards",
        "mrinal-sen",
        "manmohan-desai",
        "metropolis-1927",
        "mera-naam-joker-1970",
        "mughal-e-azam-1960",
        "mapin-publishing-house",
        "neville-tuli",
        "naseerudin-shah",
        "osian-s-cinefan-festival-of-asian-and-arab-cinema",
      ],
    },
    critical: {
      alias: "automobile-transport-and-travel-heritage-7",
      masterlists: [
        "bara-imambara-at-lucknow",
        "chittaprosad-bhattacharya",
        "ranthambore",
        "r-r-bharadwaj",
      ],
    },
    material: {
      alias: "photography-in-india-3",
      masterlists: [
        "art-heritage-annual",
        "ebrahim-alkazi",
        "himalaya",
        "geeta-kapur",
      ],
    },
    meaningful: {
      alias: "the-history-and-historiography-of-scholarship-in-the-arts-12",
      masterlists: [
        "sayed-haider-raza",
        "somnath-hore",
        "shatranj-ke-khilari-1977",
        "song-synopsis-booklets",
      ],
    },
    researchCategories: [
      "cinema-as-a-critical-educational-resource_1",
      "photography-in-india_3",
      "automobile-transport-and-travel-heritage_7",
      "the-history-and-historiography-of-scholarship-in-the-arts_12",
    ],
  });
  const [selectedValues, setSelectedValues] = useState({
    core: "1_Cinema as a Critical Educational Resource",
    material: "3_Photography in India",
    critical: "7_Automobile, Transport and Travel Heritage",
    meaningful: "12_The History & Historiography of Scholarship in the Arts",
    researchCategories: [
      [
        {
          key: "alias",
          value: "cinema-as-a-critical-educational-resource-1",
        },
        {
          key: "id",
          value: 1,
        },
        {
          key: "name",
          value: "Cinema as a Critical Educational Resource",
        },
        {
          key: "value",
          value: "1_CinemaEducation",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Core",
        },
        {
          key: "research_category_as_in_website",
          value: "1_CinemaEducation_Core",
        },
        {
          key: "images",
          value: [
            "cinema-as-a-critical-educational-resource_1-introduction-carousel",
          ],
        },
        {
          key: "description",
          value:
            "Cinema as a Critical Educational Resource focuses on gaining an understanding of India through the lens of cinema. It deals with rare gems from Indian cinema and beyond to chronicle the history and culture of India and investigates how cinema captures the nuances of Indian life, portraying the experiences, struggles and aspirations of its people. Using critical resources such as film publicity material, including booklets, lobby cards and posters, this research area offers unique insights into its cultural diversity, social fabric and historical evolution.\n",
        },
      ],
      [
        {
          key: "alias",
          value: "modern-and-contemporary-indian-fine-arts-2",
        },
        {
          key: "id",
          value: 2,
        },
        {
          key: "name",
          value: "Modern and Contemporary Indian Fine Arts",
        },
        {
          key: "value",
          value: "2_FineArts",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Core",
        },
        {
          key: "research_category_as_in_website",
          value: "2_FineArts_Core",
        },
        {
          key: "images",
          value: [
            "modern-and-contemporary-indian-fine-arts_2-introduction-carousel",
          ],
        },
        {
          key: "description",
          value:
            "Modern and Contemporary Indian Fine Arts delves into the evolution and significance of Indian fine arts, offering a comprehensive resource for understanding the artistic expressions that shape modern India. It examines the contributions of individual artists, the impact of polymaths and the ideas and historical movements that have influenced Indian art over the last hundred years. Through curated collections and critical analyses, this classification provides a deep perspective on the cultural and historical contexts, pivotal events and creative ideologies that have shaped modern and contemporary Indian art.\n",
        },
      ],
      [
        {
          key: "alias",
          value: "photography-in-india-3",
        },
        {
          key: "id",
          value: 3,
        },
        {
          key: "name",
          value: "Photography in India",
        },
        {
          key: "value",
          value: "3_Photography",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Material",
        },
        {
          key: "research_category_as_in_website",
          value: "3_Photography_Material",
        },
        {
          key: "images",
          value: ["photography-in-india_3-introduction-carousel"],
        },
        {
          key: "description",
          value:
            "Photography in India explores the countrys rich historical evolution through defining moments of societal change as well as everyday life. It investigates how photographs serve as custodians of memory and history, offering a unique insight on Indias cultural and social landscape. It explains how photography becomes a powerful medium for documenting and interpreting the nations diverse experiences.\n",
        },
      ],
      [
        {
          key: "alias",
          value: "the-printed-image-popular-arts-and-crafts-4",
        },
        {
          key: "id",
          value: 4,
        },
        {
          key: "name",
          value: "The Printed Image, Popular Arts, and Crafts",
        },
        {
          key: "value",
          value: "4_PopCulture",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Material",
        },
        {
          key: "research_category_as_in_website",
          value: "4_PopCulture_Material",
        },
        {
          key: "images",
          value: [
            "the-printed-image-popular-arts-and-crafts_4-introduction-carousel",
            "the-printed-image-popular-arts-and-crafts_4-introduction-carousel2",
          ],
        },
        {
          key: "description",
          value:
            "The Printed Image, Popular Arts, and Crafts examines the evolution and impact of printed images, including posters, advertisements and calendars, alongside traditional arts and crafts. It explores how these visual forms reflect and influence societal values, historical contexts and regional identities. By delving into the interplay between popular arts and crafts, this research area uncovers Indias rich cultural heritage, offering valuable insights into her social dynamics and artistic expressions. \n",
        },
      ],
      [
        {
          key: "alias",
          value: "indias-architectural-heritage-as-inspiration-5",
        },
        {
          key: "id",
          value: 5,
        },
        {
          key: "name",
          value: "India's Architectural Heritage as Inspiration",
        },
        {
          key: "value",
          value: "5_ArcHeritage",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Material",
        },
        {
          key: "research_category_as_in_website",
          value: "5_ArcHeritage_Material",
        },
        {
          key: "images",
          value: [
            "indias-architectural-heritage-as-inspiration_5-introduction-carousel",
          ],
        },
        {
          key: "description",
          value:
            "Indias Architectural Heritage as Inspirationinvestigates how historical monuments, traditional building techniques and regional styles influence contemporary architecture, art, urban planning and design. It highlights the preservation challenges, adaptive reuse strategies and cultural narratives associated with iconic landmarks and lesser-known monuments of India. By studying the interplay between past architectural achievements and present-day creative expressions, this category aims to showcase the enduring legacy of Indian architecture.\n",
        },
      ],
      [
        {
          key: "alias",
          value: "economics-of-art-cinema-and-culture_industry-6",
        },
        {
          key: "id",
          value: 6,
        },
        {
          key: "name",
          value: "Economics of Art & Cultural Industry",
        },
        {
          key: "value",
          value: "6_EconomicsArt",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Material",
        },
        {
          key: "research_category_as_in_website",
          value: "6_EconomicsArt_Material",
        },
        {
          key: "images",
          value: [
            "economics-of-art-cinema-and-culture_industry_6-introduction-carousel",
          ],
        },
        {
          key: "description",
          value:
            "Economics of Art & Cultural Industry explores the economic aspects of art and the cultural industry, illuminating the intricate interplay between creativity and commerce. It investigates the establishment and structuring of the art auction market, the evolution of market dynamics, and the privileging of certain artists and their economic trajectories. The analysis extends to the impact of government policies and the changing global understanding of India on cultural trade. This research area offers a comprehensive understanding of the financial forces driving India’s cultural landscape.\n",
        },
      ],
      [
        {
          key: "alias",
          value: "automobile-transport-and-travel-heritage-7",
        },
        {
          key: "id",
          value: 7,
        },
        {
          key: "name",
          value: "Automobile, Transport and Travel Heritage",
        },
        {
          key: "value",
          value: "7_Travel",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Critical",
        },
        {
          key: "research_category_as_in_website",
          value: "7_Travel_Critical",
        },
        {
          key: "images",
          value: [
            "automobile-transport-and-travel-heritage_7-introduction-carousel",
            "automobile-transport-and-travel-heritage_7-introduction-carousel2",
            "automobile-transport-and-travel-heritage_7-introduction-carousel3",
          ],
        },
        {
          key: "description",
          value:
            "Automobile, Transport and Travel Heritage throws light on the preservation of the automobile, by introducing the reader to iconic vehicles, historic routes and travel-related infrastructure. How transportation has shaped urbanization, tourism and regional connectivity across India, underscoring the importance of preserving and documenting her diverse transportation heritage for future generations and fostering a deeper appreciation of its historical and contemporary significance.",
        },
      ],
      [
        {
          key: "alias",
          value: "uncertainty-mystery-magic-horror-sci-fi-gambling-8",
        },
        {
          key: "id",
          value: 8,
        },
        {
          key: "name",
          value: "Uncertainty Mystery | Magic | Horror | Sci-Fi | Gambling",
        },
        {
          key: "value",
          value: "8_Uncertainty",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Critical",
        },
        {
          key: "research_category_as_in_website",
          value: "8_Uncertainty_Critical",
        },
        {
          key: "images",
          value: [
            "uncertainty-mystery-magic-horror-sci-fi-gambling_8-introduction-carousel",
            "uncertainty-mystery-magic-horror-sci-fi-gambling_8-introduction-carousel",
          ],
        },
        {
          key: "description",
          value:
            "Uncertainty - Mystery | Magic | Horror | Sci-Fi | Gambling delves into how mystery, magic, horror, science fiction and gambling narratives reflect and shape perceptions of uncertainty in Indian literature, cinema, folklore and popular culture. It investigates how these themes engage with human psychology, societal fears and aspirations, offering insights into the ways uncertainty is portrayed and interpreted. It examines the cultural significance of supernatural beliefs, speculative fiction and risk-taking behaviors, highlighting their roles in navigating uncertainty and coping with the unknown.",
        },
      ],
      [
        {
          key: "alias",
          value: "social-responsibility-of-the-creative-mind-9",
        },
        {
          key: "id",
          value: 9,
        },
        {
          key: "name",
          value: "Social Responsibility of the Creative Mind",
        },
        {
          key: "value",
          value: "9_SocialDuty",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Critical",
        },
        {
          key: "research_category_as_in_website",
          value: "9_SocialDuty_Critical",
        },
        {
          key: "images",
          value: [
            "social-responsibility-of-the-creative-mind_9-introduction-carousel",
          ],
        },
        {
          key: "description",
          value:
            "Social Responsibility of the Creative Mind throws light on the ethical obligations and impacts of artistic and creative endeavors in Indian society. It explains how artists, writers, filmmakers, musicians and other creatives engage with and respond to social issues, including inequality, justice, environmental sustainability and cultural representation. It examines the ways in which creative expressions can shape public opinion, challenge norms and advocate positive change.",
        },
      ],
      [
        {
          key: "alias",
          value: "the-changing-smile-of-childhood-10",
        },
        {
          key: "id",
          value: 10,
        },
        {
          key: "name",
          value: "The Changing Smile of Childhood",
        },
        {
          key: "value",
          value: "10_Childhood",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Critical",
        },
        {
          key: "research_category_as_in_website",
          value: "10_Childhood_Critical",
        },
        {
          key: "images",
          value: [
            "the-changing-smile-of-childhood_10-introduction-carousel",
            "the-changing-smile-of-childhood_10-introduction-carousel2",
          ],
        },
        {
          key: "description",
          value:
            "The Changing Smile of Childhood & Its Second Coming explores the evolving portrayal and significance of childhood in contemporary India. It does so by highlighting shifts in cultural perceptions, social norms and the representation of children in various media, including literature, art, film and advertising. It examines how childhood experiences are depicted, valued and understood across different socio-economic and cultural contexts. By analyzing the continuum from childhood through to old age, this research seeks to understand the holistic journey of human development and aging within the Indian context. \n",
        },
      ],
      [
        {
          key: "alias",
          value: "the-erotic-discipline-within-creativity-11",
        },
        {
          key: "id",
          value: 11,
        },
        {
          key: "name",
          value: "The Erotic Discipline within Creativity",
        },
        {
          key: "value",
          value: "11_Eroticism",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Critical",
        },
        {
          key: "research_category_as_in_website",
          value: "11_Eroticism_Critical",
        },
        {
          key: "images",
          value: [
            "the-erotic-discipline-within-creativity_11-introduction-carousel",
          ],
        },
        {
          key: "description",
          value:
            "The Erotic Discipline within Creativity explores the complex interplay between eroticism and artistic expression in India. It investigates how themes of sensuality, desire and intimacy have been depicted within various creative disciplines such as literature, visual arts, dance and cinema. It explores cultural attitudes towards eroticism, the societal reception of artistic representations and the evolution of erotic themes in different historical periods. It provides an understanding of how eroticism has influenced and shaped Indian art and culture, contributing to broader discussions on aesthetics, identity and human expression.\n",
        },
      ],
      [
        {
          key: "alias",
          value: "the-history-and-historiography-of-scholarship-in-the-arts-12",
        },
        {
          key: "id",
          value: 12,
        },
        {
          key: "name",
          value: "The History & Historiography of Scholarship in the Arts",
        },
        {
          key: "value",
          value: "12_Scholarship",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Meaningful",
        },
        {
          key: "research_category_as_in_website",
          value: "12_Scholarship_Meaningful",
        },
        {
          key: "images",
          value: [
            "the-history-and-historiography-of-scholarship-in-the-arts_12-introduction-carousel",
            "the-history-and-historiography-of-scholarship-in-the-arts_12-introduction-carousel2",
          ],
        },
        {
          key: "description",
          value:
            "The History & Historiography of Scholarship in the Arts delves into the evolution and analysis of artistic scholarship in India. It studies the historical development of arts scholarship, tracing key movements, influential figures and pivotal works that have shaped the understanding of Indian art forms. It also involves historiography, analyzing how historical contexts and cultural perspectives have influenced the study and interpretation of the arts over time.It highlights the shifts in critical approaches and the impact of socio-political factors on art history. \n",
        },
      ],
      [
        {
          key: "alias",
          value: "seeking-justice-and-political-integrity-13",
        },
        {
          key: "id",
          value: 13,
        },
        {
          key: "name",
          value: "Seeking Justice and Political Integrity",
        },
        {
          key: "value",
          value: "13_SeekJustice",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Meaningful",
        },
        {
          key: "research_category_as_in_website",
          value: "13_SeekJustice_Meaningful",
        },
        {
          key: "images",
          value: [
            "seeking-justice-and-political-integrity_13-introduction-carousel",
          ],
        },
        {
          key: "description",
          value:
            "Seeking Justice and Political Integrity delves into the contributions of activists, freedom fighters  and civil society in advocating for justice and ethical governance. It explores the ways in which grassroots movements and public opinion shape political integrity and accountability. This research is crucial for understanding the evolving landscape of Indian democracy and the ongoing efforts to achieve a just and equitable society.\n",
        },
      ],
      [
        {
          key: "alias",
          value: "india-and-her-relationship-with-the-world-14",
        },
        {
          key: "id",
          value: 14,
        },
        {
          key: "name",
          value: "India and her Relationship with the World",
        },
        {
          key: "value",
          value: "14_IndoWorld",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Meaningful",
        },
        {
          key: "research_category_as_in_website",
          value: "14_IndoWorld_Meaningful",
        },
        {
          key: "images",
          value: [
            "india-and-her-relationship-with-the-world_14-introduction-carousel",
          ],
        },
        {
          key: "description",
          value:
            "India and her Relationship with the World examines Indias historical and contemporary relationships with various countries, emphasizing diplomatic, economic, cultural and social dimensions. It explores the influence of Indian art, cinema, cuisine and philosophy beyond its borders, showcasing Indias soft power and its ability to shape global perceptions. \n",
        },
      ],
      [
        {
          key: "alias",
          value: "the-religious-spiritual-poetic-philosophical-continuum-15",
        },
        {
          key: "id",
          value: 15,
        },
        {
          key: "name",
          value: "The Religious-Spiritual-Poetic-Philosophical Continuum",
        },
        {
          key: "value",
          value: "15_SpiritualPoetics",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Meaningful",
        },
        {
          key: "research_category_as_in_website",
          value: "15_SpiritualPoetics_Meaningful",
        },
        {
          key: "images",
          value: [
            "the-religious-spiritual-poetic-philosophical-continuum_15-introduction-carousel",
          ],
        },
        {
          key: "description",
          value:
            "The Religious-Spiritual-Poetic-Philosophical Continuum delves into the intricate interplay between Indias religious beliefs, spiritual practices, poetic traditions and philosophical thought. The continuum explores how these elements are interwoven and reflect Indias cultural and intellectual heritage. By studying ancient texts, contemporary literature, rituals and philosophical discourses, this category seeks to understand how these domains influence one another and contribute to the holistic Indian worldview. It reviews the ways in which religious and spiritual insights find expression in poetry and philosophy, offering a nuanced perspective on Indias quest for meaning and transcendence. \n",
        },
      ],
      [
        {
          key: "alias",
          value: "the-animal-human-nature-continuum-16",
        },
        {
          key: "id",
          value: 16,
        },
        {
          key: "name",
          value: "The Animal-Human-Nature Continuum",
        },
        {
          key: "value",
          value: "16_Continuum",
        },
        {
          key: "relevance_to_self_discovery_journey",
          value: "Meaningful",
        },
        {
          key: "research_category_as_in_website",
          value: "16_Continuum_Meaningful",
        },
        {
          key: "images",
          value: ["the-animal-human-nature-continuum_16-introduction-carousel"],
        },
        {
          key: "description",
          value:
            "The Animal-Human-Nature Continuum explores the interconnectedness of ecological systems and the relationships between animals, humans and nature. It examines the depiction of canines and felines in Indian art, cinema and culture, questioning the efforts made to foster sensitivity and empathy. By investigating the affection for animals and the factors contributing to their coexistence with humans, this research promotes a holistic understanding of the natural world, encouraging empathy.\n",
        },
      ],
    ],
    coreMasterlist: [
      "aruna-vasudev:Aruna Vasudev",
      "amitabh-bachchan:Amitabh Bachchan",
      "akira-kurosawa:Akira Kurosawa",
      "bimal-roy:Bimal Roy",
      "chidananda-dasgupta:Chidananda Dasgupta",
      "charlie-chaplin:Charlie Chaplin",
      "dilip-kumar:Dilip Kumar",
      "fritz-lang:Fritz Lang",
      "kamal-amrohi:Kamal Amrohi",
      "k-a-abbas:K.A. Abbas",
      "leonard-schrader-collection-of-vintage-hollywood-lobby-cards:Leonard Schrader Collection of Vintage Hollywood Lobby Cards",
      "mrinal-sen:Mrinal Sen",
      "manmohan-desai:Manmohan Desai",
      "metropolis-1927:Metropolis.1927 ",
      "mera-naam-joker-1970:Mera Naam Joker.1970 ",
      "mughal-e-azam-1960:Mughal-e-Azam.1960 ",
      "mapin-publishing-house:Mapin Publishing House",
      "neville-tuli:Neville Tuli",
      "naseerudin-shah:Naseerudin Shah",
      "osian-s-cinefan-festival-of-asian-and-arab-cinema:Osian's-Cinefan Festival of Asian & Arab Cinema",
    ],
    materialMasterlist: [
      "art-heritage-annual:Art Heritage Annual",
      "ebrahim-alkazi:Ebrahim Alkazi",
      "himalaya:Himalaya ",
      "geeta-kapur:Geeta Kapur",
    ],
    criticalMasterlist: [
      "bara-imambara-at-lucknow:Bara Imambara at Lucknow",
      "chittaprosad-bhattacharya:Chittaprosad Bhattacharya",
      "ranthambore:Ranthambore ",
      "r-r-bharadwaj:R.R. Bharadwaj",
    ],
    meaningfulMasterlist: [
      "sayed-haider-raza:Sayed Haider Raza",
      "somnath-hore:Somnath  Hore",
      "shatranj-ke-khilari-1977:Shatranj Ke Khilari",
      "song-synopsis-booklets:Song Synopsis Booklets",
    ],
    researchCategoriesMapping: {
      core: {
        alias: "cinema-as-a-critical-educational-resource-1",
        masterlists: [
          "aruna-vasudev",
          "amitabh-bachchan",
          "akira-kurosawa",
          "bimal-roy",
          "chidananda-dasgupta",
          "charlie-chaplin",
          "dilip-kumar",
          "fritz-lang",
          "kamal-amrohi",
          "k-a-abbas",
          "leonard-schrader-collection-of-vintage-hollywood-lobby-cards",
          "mrinal-sen",
          "manmohan-desai",
          "metropolis-1927",
          "mera-naam-joker-1970",
          "mughal-e-azam-1960",
          "mapin-publishing-house",
          "neville-tuli",
          "naseerudin-shah",
          "osian-s-cinefan-festival-of-asian-and-arab-cinema",
        ],
      },
      material: {
        alias: "photography-in-india-3",
        masterlists: [
          "art-heritage-annual",
          "ebrahim-alkazi",
          "himalaya",
          "geeta-kapur",
        ],
      },
      critical: {
        alias: "automobile-transport-and-travel-heritage-7",
        masterlists: [
          "bara-imambara-at-lucknow",
          "chittaprosad-bhattacharya",
          "ranthambore",
          "r-r-bharadwaj",
        ],
      },
      meaningful: {
        alias: "the-history-and-historiography-of-scholarship-in-the-arts-12",
        masterlists: [
          "sayed-haider-raza",
          "somnath-hore",
          "shatranj-ke-khilari-1977",
          "song-synopsis-booklets",
        ],
      },
    },
    masterlist: [
      "sayed-haider-raza:Sayed Haider Raza",
      "somnath-hore:Somnath  Hore",
      "shatranj-ke-khilari-1977:Shatranj Ke Khilari",
      "song-synopsis-booklets:Song Synopsis Booklets",
    ],
  });
  const [researchCategories, setResearchCategories] = useState([
    "cinema-as-a-critical-educational-resource_1",
    "photography-in-india_3",
    "automobile-transport-and-travel-heritage_7",
    "the-history-and-historiography-of-scholarship-in-the-arts_12",
  ]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [errorMsgFlag, setErrorMsgFlag] = useState(false);
  const navigate = useNavigate();
  const { isOpen } = useDisclosure();

  const totalSteps = 4; // Update this if the number of steps changes

  const nextStep = () => setStep(step + 1);
  const previousStep = () => setStep(step - 1);

  const handleSubmit = () => {
    signup(formData)
      .then((response) => {
        if (response?.success) {
          navigate("/register/done");
        } else {
          setErrorMsg(
            response?.errors || ["Registration failed. Please try again."]
          );
          setErrorMsgFlag(true);
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => {
        console.error("Error during registration:", error);
      });
  };
  return (
    <Layout position={"relative"}>
      {errorMsgFlag && errorMsg && (
        <Alert status="error" justifyContent={"space-between"}>
          <Box>
            <UnorderedList>
              {errorMsg.map((errorMessage, index) => (
                <ListItem key={index}>{errorMessage}</ListItem>
              ))}
            </UnorderedList>
          </Box>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => setErrorMsgFlag(false)}
          />
        </Alert>
      )}
      <Box position={"relative"}>
        {step === 1 && (
          <Step1
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
          />
        )}
        {step === 2 && (
          <Step2
            formData={formData}
            isOpen={isOpen}
            setFormData={setFormData}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        )}
        {step === 3 && (
          <Step3
            formData={formData}
            setFormData={setFormData}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            nextStep={nextStep}
            previousStep={previousStep}
            researchCategories={researchCategories}
            setResearchCategories={setResearchCategories}
          />
        )}
        {step === 4 && (
          <Step4
            formData={formData}
            setFormData={setFormData}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            handleSubmit={handleSubmit}
            previousStep={previousStep}
          />
        )}
      </Box>

      <Box>
        {step !== 4 && <StepIndicator step={step} totalSteps={totalSteps} />}
      </Box>
    </Layout>
  );
};

export default Register;