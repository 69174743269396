import { Stack } from "@chakra-ui/react";
//Core files
import React, { useEffect, useState } from "react";
import { EffectCreative, Navigation, Pagination } from "swiper";

//Service files
import * as getEssayData from "../../../services/Exhibitions";

//Component file
import ImageSlider from "../../../FunctionalComponents/ImageSlider/ImageSlider";
import { FullScreenImage } from "../../../FunctionalComponents/FullScreenImage";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";
import { useParams } from "react-router-dom";
export default function Essay({ setReadMoreFlag, readmoreNavigation, artAlias, innerTab }) {
  const [essayData, setEssayData] = useState([{}]);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType=parts[1];
  const {alias} = useParams();
  const masterlist = alias;
  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const findItem = (items, key) => items.find(it => it.key === key) || {};

  const citationFactor = (data) => {
    return data.map((item) => {
      const fullImage = findItem(item, "direct_url_of_cover_image").value || "";
      const titleItem = findItem(item, "title");
      const author = findItem(item, "creator_name");
      const dateofArt = findItem(item, "sortable_year");
      const title_of_essay = titleItem.value ? titleItem : "";
  
      return {
        desc: findItem(item, "bibliographic_citation").value || "",
        imgPath: fullImage,
        content: findItem(item, "description").value || "",
        designerdesc: findItem(item, "bibliographic_caption").value || "",
        fullImg: fullImage,
        designerData: [title_of_essay, author, dateofArt],
      };
    });
  };
  

  useEffect(() => {
    const getData = async () => {
      const result = await getEssayData.getEssayDataByExhibition(
        masterlistType,
        masterlist,
        innerTab
      );

      if (result)
      {
        setEssayData(citationFactor(result));
      }
    };

    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
    }
  }, [readmoreNavigation]);

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };

  return (
    <Stack position={"relative"} id="allslideressay" pt={8} height={"100vh"} width={{ base: "100%",  md: "1076px" }}>
      {essayData && essayData.length > 0 ? (
        <>
          <ImageSlider
            sliderData={essayData}
            handleImageClick={handleImageClick}
            modules={[EffectCreative, Navigation, Pagination]}
            width={{ base: "100%", md: "auto" }}
            height={"100%"}
            sheight={"511px"}
          />

          {fullScreenImage && (
            <FullScreenImage
              isOpen={fullScreenImage}
              onClose={() => showImages(null, false)}
              imageData={ImgData}
              data={essayData}
              clickedImageIndex={clickedImageIndex}
              setClickedImageIndex={setClickedImageIndex}
              setReadMoreFlag={setReadMoreFlag}
              bannerFlag={false}
              title="banner"
            />
          )}
        </>
      ) : (
        <WebpageComingSoon/>
      )}
    </Stack>
  );
}
