import { HStack, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import H2 from "../../../components/layouts/Typography/H2";
import { formatName } from "../../../util/helper";
import H3 from "../../../Theme/Typography/H3";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`;

export default function DrawerHeader({ imageTitle, data, handleClose,masterlist }) {
  return (
    <>
      <HStack
        mt={"12px"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <Stack>
          <HStack>
            {imageTitle !== undefined ? (
              <Image width={"265px"} height={"auto"} src={imageTitle} />
            ) : (
              <>
              {masterlist ? <H2 >{formatName(masterlist)}
               <Text as={"span"} color={"red"}>{" & "}</Text>
               <H3 fontSize="18px" as={"span"}>{data?.header}</H3>
               </H2>:<H3 fontSize="18px" as={"span"}>{data?.header}</H3>}
             </>
            )}
          </HStack>
          {imageTitle && <H2 className="mt-0">{data?.header}</H2>}
        </Stack>
          <HStack justifyContent={"flex-end"} width={"40px!important"} height={"20px"}>
          <Image
            
            src={closeIcon}
            cursor={"pointer"}
            onClick={() => {
              handleClose();
            }}
            width={"20px"} height={"20px"}
            objectFit={"contain"}
          />
          </HStack>
      </HStack>
    </>
  );
}