import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Small from '../../components/layouts/Typography/Small';
import { Image } from '@chakra-ui/react';
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholder = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

const ThumbnailListView = ({ data, handleDrawer,handleImageClick }) => {
    const btnRef = React.useRef();
    return (
        <VerticalTimeline layout={"1-column-left"} className="layout-thumbnail ml-0 mt-0">
            {data.map((item, index) => (
                item?.publicationYear && (
                    <VerticalTimelineElement
                        id="thumbnailtimeline"
                        className="col-12 pr-0"
                        key={index}
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                            background: "#fff",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "700",
                        }}
                        // icon={item?.publicationYear}
                        icon ={<Image src={item?.imagePath} fallbackSrc={placeholder} width={"30px"} height={"30px"}/>}
                    >
                        {item?.link ? <Small
                            fontSize={"14px!important"}
                            ref={btnRef}
                            cursor={"pointer"}
                            color="#035DA1"
                            onClick={(e) => {
                                handleDrawer && handleDrawer(item);
                                handleImageClick && handleImageClick(item?.image, item, true, index);

                            }}
                        >
                            {item?.listDesc}
                        </Small> : (<Small
                        fontSize={"14px!important"}
                            ref={btnRef}
                            cursor={"pointer"}
                            color="#035DA1"
                            onClick={(e) => {
                                handleDrawer && handleDrawer(item);
                                handleImageClick && handleImageClick(item?.image, item, true, index);
                            }}
                            minH="50px"
                        >
                            {item?.listDesc}
                        </Small>)}
                    </VerticalTimelineElement>
                )
            ))}
        </VerticalTimeline>
    );
};

export default ThumbnailListView;