import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
} from "@chakra-ui/react";

//Core files
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

// Service files
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";
import { CopyPasteService } from "../../services/CopyPaste";

//Component files
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
// import Screenings from "./EconomicsOfCinema/Screenings";
import AwardsHonours from "./EconomicsOfCinema/AwardsHonours";
import BoxOffice from "./EconomicsOfCinema/BoxOffice";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";

//Language files
import { explore } from "../../constants/constants";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import Screenings from "../../components/Screenings/Screenings";

export default function EconomicsOfCinema({ children }) {
  const { isOpen, onClose } = useDisclosure();
  const { alias } = useParams();
  const masterlist = alias;
  const masterlistType = "person-poly";

  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState(null);

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        masterlist
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].alias
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title:
              result[0].find((item) => item.key === "subject").value +
              "." +
              result[0].find((item) => item.key === "masterlist_type").value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };

    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(masterlistType,
        masterlist
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${
              it?.find((item) => item?.key === "masterlist_alias")?.value
            }/${
              it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value
            }/${
              it.find((item) => item.key === "landing_page_abbreviation")
                ?.value === "research-centre"
                ? `library`
                : ``
            }
             `,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
    window.scrollTo(0, 0);
  }, [masterlist]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        alias={alias}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} masterlistType={masterlistType} />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <EconomicsOfCinemaBody masterlist={masterlist} masterlistType={masterlistType} />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  horislinkData,
  artistDetails,
  introData,
  alias,
  masterlistType,
  ...rest
}) => {
  const oSections = [
    {
      to: `/${masterlistType}/${alias}/posts`,
      title: "",
      text: `${explore.POSTS} | ${explore.INSIGHTS}`,
    }
  ];

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        hMenu={horislinkData}
        artistDetails={artistDetails}
        handleNavigation={handleNavigation}
        aliasAPI={alias}
      />
    </>
  );
};

export const EconomicsOfCinemaBody = ({ masterlist, masterlistType }) => {
  const [tabPanelData, setTabPanelData] = useState([]);
  const [screeningCount,setScreeningCount]=useState(0);
  const [awardCount,setAwardCount]=useState(0);

  function formatData() {
    return [
      {
        name: `${explore.SCREENINGS} ${screeningCount >0 ? `(${screeningCount})`:''}`,          
        component: (
          <Screenings
            CopyPasteService={CopyPasteService}
            masterlistType={masterlistType}
            masterlist={masterlist}
            setScreeningCount={setScreeningCount}

          />
        ),
      },
      {
        name: `${explore.AWARDS_AND_HONOURS} ${awardCount >0 ? `(${awardCount})`:''}`,          
        component: (
          <AwardsHonours
            CopyPasteService={CopyPasteService}
            masterlistType={masterlistType}
            masterlist={masterlist}
            setAwardCount={setAwardCount}
          />
        ),
      },
      {
        name: `${explore.BOX_OFFICE}`,
        component: <BoxOffice masterlist={masterlist} />,
      },
    ];
  }
  useEffect(() => {
    if (masterlist) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlist,screeningCount,awardCount]);
  return (
    <>
    <Stack height={"100%"}>
    <Container maxW="auto" px={{ base: "4", md: "0" }} mx={"0"}>
      <Stack px={{ base: "0", md: "55px" }}>
        {tabPanelData && tabPanelData.length > 0 && (
          <TabMain paddingTop="10px" gap="50px" width={"1080px"} cla pos={"fixed"}
          panelTop={{ base: "65px", sm: "75px", md: "65px" }} topTogleIcon="25px" listId={"listgridbutton6"}  data={tabPanelData} />
        )}
      </Stack>
    </Container>
  </Stack>
  </>
  );
};
