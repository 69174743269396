import React, { useEffect, useRef, useState } from "react";
import {
  HStack,
  Image,
  Stack,
} from "@chakra-ui/react";
import GridView from "./Library/GridView";
import TabularView from "./Library/TabularView";
import { useNavigate, useParams } from "react-router-dom";
import {  totalFilterCount } from "../../../util/helper";
import * as getSearchLibraryData from "../../../services/Search";
import SortBy from "../../../components/ResearchCentre/Components/SortBy";
import { updateFacetsData } from "../../../util/helper";
import * as getResearchCentreBook from "../../../services/book";
import { drawerFactor } from "../../../util/ResearchCentreLibraryDrawer";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export const Library = ({
  visible,
  handleBookClick,
  SearchTerm,
  setleftFlag,
  openDrawer,
  setOpenDrawer,
  tableName,
  masterlist,
  setLibraryCount,
  masterlistType = "search",
  filterbuttonStyle
}) => {
  // const [visible, setVisible] = React.useState(false);
  const [contentClicked, setContentClicked] = useState(false);
  const [scanClicked, setScanClicked] = useState(false);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [indexValue, setIndexValue] = useState();
  const [apply, setApply] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterPills, setFilterPills] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  const navigate = useNavigate();
  const { bookAlias,tabValue } = useParams();

  const handleFilterClick = () => {
      setShowFilterBox(!showFilterBox);
  };

  const showSliderImage = (index) => {
    swiper.slideTo(index);
  };

  const handleCloseClick = () => {
    setContentClicked(false);
    setScanClicked(false);
  };

  const handleDrawer = (item) => {
    setOpenDrawer(true);
    setDrawerBook(item);
    if(masterlistType==="search"){
      navigate(`/${masterlistType}/research-centre/library/${item?.alias}?query=${encodeURIComponent(SearchTerm)}`);
    }else{
      navigate(`/${masterlistType}/${SearchTerm}/research-centre/library/${item?.alias}`);
    }
  };

  const handleClose = () => {
    handleCloseClick();
    setOpenDrawer(false);
    setDrawerBook("");
    if(masterlistType==="search"){
      navigate(-1);
      /* For future use */
      // navigate(`/${masterlistType}/research-centre/library?query=${encodeURIComponent(SearchTerm)}`);
    }else{
      navigate(`/${masterlistType}/${SearchTerm}/research-centre/library/`);
    }

  };

  /* Future Use */
  const handleContentClicked = (index, text) => {
    setIndexValue(index);
    showSliderImage(2);
    if (text === "Contents Page→") {
      setContentClicked(true);
      setScanClicked(false);
    }
    if (text === "Scanned Pages→") {
      setScanClicked(true);
      setContentClicked(false);
    }
  };

  const [filterOptions, setFilterOptions] = useState({
    search: "",
    filters: {},
    gate:"AND",
    sortBy_v: "creator_surname"
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches",e.target.value)
  };

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";  
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
        case "gate":
          selectedString = filterOptions.gate;
          break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }
  
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
  
    if (selectedString !== type) {
      selectedString = type;
    }
  
    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
        case "gate":
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            gate: selectedString,
          }));
          break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };
  const citationFactor = (data) => {
    return data.map((item) => {
      const imagePath =item?.find((nestedItem) => nestedItem?.key === "direct_url_of_cover_image")?.value || item?.find((nestedItem) => nestedItem?.key === "direct_url_of_preview_image")?.value || "";
      const firstName = item?.find((nestedItem) => nestedItem?.key === "creator_first_name")?.value
      const lastName = item?.find((nestedItem) => nestedItem?.key === "creator_surname")?.value
      const creatorName=item?.find((nestedItem) => nestedItem?.key === "creator_name")?.value
      const author = lastName && firstName ? `${firstName} ${lastName}` : "";
      const bookKey =
        item?.find((nestedItem) => nestedItem?.key === "title")?.value || null;
      const book = bookKey;
      const link =
        item?.find((nestedItem) => nestedItem?.key === "drawer_link")?.value ||
        null;

      const docType =
        item?.find((nestedItem) => nestedItem?.key === "document_type")
          ?.value || null;
      const typeCreator =
        item?.find((nestedItem) => nestedItem?.key === "type_of_creator")
          ?.value || null;
          const fullname = creatorName!=="" ? author : creatorName;
          const sliderImage =
        item?.find((nestedItem) => nestedItem?.key === "drawer_image_alias")
          ?.value ?
        item?.find((nestedItem) => nestedItem?.key === "drawer_image_alias")
          ?.value : '';
      const bibliograhpic_citation =
        item?.find((nestedItem) => nestedItem?.key === "bibliograhpic_citation")
          ?.value ||
        item?.find((nestedItem) => nestedItem?.key === "bibliographic_citation")
          ?.value ||
        "";
        const bibliograhpic_caption =
        item?.find((nestedItem) => nestedItem?.key === "bibliographic_caption")
          ?.value ||
        item?.find((nestedItem) => nestedItem?.key === "bibliographic_caption")
          ?.value ||
        "";
      const desc =  bibliograhpic_citation || "";
      const listDesc= bibliograhpic_caption || '';
      const alias = item?.find(
        (nestedItem) => nestedItem?.key === "alias"
      )?.value;
      const title = item?.find(
        (nestedItem) => nestedItem?.key === "title"
      )?.value;
      const header = `${title ? title :''}`;
      const publisher = item?.find(
        (nestedItem) => nestedItem?.key === "place_of_publication"
      )?.value;
      const bookCaption =
        item?.find((nestedItem) => nestedItem?.key === "book_caption")?.value ||
        null;
      const publicationYear =
        item?.find((nestedItem) => nestedItem?.key === "sortable_year")
          ?.value || null;
          const firstEdition =
        item?.find((nestedItem) => nestedItem?.key === "date_of_first_edition")
          ?.value || null;
      // publicationYear
      return {
        imagePath,
        desc,
        header,
        bookKey,
        link,
        typeCreator,
        book,
        author,
        // imageTitle,
        docType,
        sliderImage,
        fullname,
        alias,
        title,
        publisher,
        bookCaption,
        publicationYear,
        firstEdition,
        listDesc,
        body:[
          {
            text: "Index Page →",
          }
        ],
        footer: [
          {
            text: "Contents Page→",
          },
          {
            text: "Scanned Pages→",
          },
          
          ]
      };
    });
  };

  // New library data

  const [facets, setFacets] = useState([]);
  const [sort, setSort] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const initialLoadComplete = useRef(false);
  const [triggerFetch, setTriggerFetch] = useState(false);

  async function fetchData() {
    if (!navigator.onLine) {
      alert("No network connection. Please check your internet connection and try again.");
      setHasMore(false);
      return;
    }
    try {
      setIsLoading(true);
      const result = await getSearchLibraryData.searchResearchLibraryTab(
        SearchTerm,
        filterOptions,
        1
      );
      setIsLoading(false);

      if (result && result?.data.length > 0) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        setLibraryCount(result?.total);
        if (!initialLoadComplete.current) {
          setPage(page + 1);
          initialLoadComplete.current = true;
        }
        
        if (result?.facetsHeaders && result?.facets) {       
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        
          if (formattedFacets && formattedFacets.length > 0) {
            setFilterOptions((prevFilterOptions) => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                ...formattedFacets.reduce((acc, facet) => {
                  acc[facet.key] = [];
                  return acc;
                }, {}),
              },
            }));
          }
        }
        
        if(result.total===result?.data.length) {
          setHasMore(false)
        }

        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
      }else{
        setData([])
        setLibraryCount(0)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const handleResetPage=()=>{
    initialLoadComplete.current = false;
    setHasMore(true);
    setPage(1);
    setTriggerFetch(prev => !prev);
  }

  useEffect(() => {
    handleResetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchTerm]);
  useEffect(() => {
    fetchData();
    setFilterPills(filterOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFetch]);

  const fetchMoreData = async () => {
    if (!navigator.onLine) {
      alert("No network connection. Please check your internet connection and try again.");
      setHasMore(false);
      return;
    }
    try {
      const result = await getSearchLibraryData.searchResearchLibraryTab(
        SearchTerm,
        filterOptions,
        page
      );

      if (result) {
        const processedData = citationFactor(result?.data);
        setData((prevData) => [...prevData, ...processedData]);
        setPage(page + 1);
        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        if (data?.length >= result?.total){
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  async function fetchFilterData(page=1) {
    if (!navigator.onLine) {
      alert("No network connection. Please check your internet connection and try again.");
      setHasMore(false);
      return;
    }
    try {
      setIsLoading(true);
      const result = await getSearchLibraryData.searchResearchLibraryTab(
        SearchTerm,
        filterOptions,
        page
      );
      setIsLoading(false);
      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        setPage(prev=>prev+1)
        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        const filtercnt= totalFilterCount(filterOptions?.filters)
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }
        if (result?.data?.length >= result?.total) {
          setHasMore(false);
        }

        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (apply) {
      setApply(false);
      setHasMore(true);
      setPage(1)
      fetchFilterData();
      setFilterPills(filterOptions)

    }
    const filtercnt= totalFilterCount(filterOptions?.filters)
    setFilterCount(filtercnt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  //drawer dynamic
  const [drawerBook, setDrawerBook] = useState("");

  const [bookData, setBookData] = useState(null);
  useEffect(() => {
    if (data && bookAlias && tabValue==="library") {
      const item = data.find((it) => it.alias === bookAlias);
      if (item !== undefined && window.location.pathname.includes('library')) {
        setDrawerBook(item);
        setOpenDrawer(true);
      }
    }

    const fetchData = async () => {
      let result;
      result = await getResearchCentreBook.getResearchCentreBookbyBookAlias(
        bookAlias
      );
      setBookData(drawerFactor(result?.data[0]?.staticBookData,result?.data[1]?.dynamicBookData));
      setOpenDrawer(true);
    };
    if (data && bookAlias && bookAlias!==undefined && tabValue==="library") {
      fetchData();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  }, [data, bookAlias]);

    const handleReset = () => {
      setFilteredResultCount(0);
      const initializedFilters = facets.reduce((acc, facet) => {
        acc[facet.key] = [];
        return acc;
      }, {});
      setFilterOptions({
        search: "",
        filters: initializedFilters
      });
      setFilterCount(0)
      setSearchTerm("");
    }
    
  return (
    <>
    {!isLoading ? (
    <Stack position={"relative"}>
      {sort && sort?.length>0 &&  
          <SortBy
          sort={sort}
          masterlistType={masterlistType}
          filterOptions={filterOptions}
          handleCheckboxChange={handleCheckboxChange}
          setApply={setApply}
          />
          }
      {visible ? (
        <>
          <GridView
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleBookClick={handleBookClick}
            contentClicked={contentClicked}
            scanClicked={scanClicked}
            setContentClicked={setContentClicked}
            setScanClicked={setScanClicked}
            setleftFlag={setleftFlag}
            tableName={tableName}
            masterlist={masterlist}
            setLibraryCount={setLibraryCount}
            masterlistType={masterlistType}
            //new data props
            fetchMoreData={fetchMoreData}
            data={data}
            filterPills={filterPills}
            setFilterPills={setFilterPills}
            drawerBook={drawerBook}
            handleClose={handleClose}
            showFilterBox={showFilterBox}
            handleFilterClick={handleFilterClick}
            hasMore={hasMore}
            handleDrawer={handleDrawer}
            setShowFilterBox={setShowFilterBox}
            setApply={setApply}
            sort={sort}
            handleCheckboxChange={handleCheckboxChange}
            handleSearch={handleSearch}
            filterOptions={filterOptions}
            filteroptionData={facets}
            setFilterOptions={setFilterOptions}
            setSearchTerm={setSearchTerm}
            filterCount={filterCount}
            searchTerm={searchTerm}
            handleReset={handleReset}
            bookAlias={bookAlias}
            setSwiper={setSwiper}
            handleContentClicked={handleContentClicked}
            indexValue={indexValue}
            bookData={bookData}
            filterbuttonStyle={filterbuttonStyle}
            filteredResultCount={filteredResultCount}

          />
        </>
      ) : (
        <>
          <TabularView
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleBookClick={handleBookClick}
            contentClicked={contentClicked}
            scanClicked={scanClicked}
            setContentClicked={setContentClicked}
            setScanClicked={setScanClicked}
            setleftFlag={setleftFlag}
            tableName={tableName}
            masterlist={masterlist}
            masterlistType={masterlistType}
            filterPills={filterPills}
            setFilterPills={setFilterPills}
            //new data props
            fetchMoreData={fetchMoreData}
            data={data}
            drawerBook={drawerBook}
            handleClose={handleClose}
            showFilterBox={showFilterBox}
            handleFilterClick={handleFilterClick}
            hasMore={hasMore}
            handleDrawer={handleDrawer}
            setShowFilterBox={setShowFilterBox}
            setApply={setApply}
            sort={sort}
            handleCheckboxChange={handleCheckboxChange}
            handleSearch={handleSearch}
            filterOptions={filterOptions}
            filteroptionData={facets}
            setFilterOptions={setFilterOptions}
            setSearchTerm={setSearchTerm}
            filterCount={filterCount}
            searchTerm={searchTerm}
            handleReset={handleReset}
            bookAlias={bookAlias}
            setSwiper={setSwiper}
            handleContentClicked={handleContentClicked}
            indexValue={indexValue}
            bookData={bookData}
            filterbuttonStyle={filterbuttonStyle}
            filteredResultCount={filteredResultCount}

          />
        </>
      )}
    </Stack>):
    (
      <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
          <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
      </HStack>
    )
  }
    </>
  );
};