import {
  Box,
  Stack,
  Container,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Language files
import { explore } from "../../constants/constants";
// import FilmoGraphyTabpanel from "./BodyOfWork/Tabs/FilmoGraphyTabpanel";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import BookletSongs from "./BodyOfWork/SynopsisBooklet/BookletSongs";
import BrandAmbassdorShip from "./BodyOfWork/BrandAmbassdorShip/BrandAmbassdorShip";
import FilmoGraphyTabpanel from "../../components/Filmography/FilmoGraphyTabpanel";

//S3 Bucket osw-dev-images

export const BodyOfWorkBody = ({
  bookletSong,
  setBookletSong,
  flag,
  masterlist,
  masterlistType
}) => {

  const navigate = useNavigate();
  let { gridview } = useParams();
  const {bookletAlias}=useParams();
  const [dataCount,setDataCount]=useState(0);

  React.useEffect(() => {
    if (gridview) {
      navigate(`/${masterlistType}/${masterlist}/filmography`, {
        replace: true,
      });
    }
  }, [gridview, masterlist, masterlistType, navigate]);

  const handlePrint = () => {
    window.print();
  };

  const handleImageClick = () => {
    setBookletSong(!bookletSong);
  };

  function formatData() {
    return [
      {
        name: `${explore.FILMOGRAPHY} ${dataCount >0 ? `(${dataCount})`:''}`,          
        component:<FilmoGraphyTabpanel handlePrint={handlePrint} masterlist={masterlist} masterlistType={masterlistType} setDataCount={setDataCount}/>,

      },
      {
        name: `${explore.BRAND_AMBASSDORSHIP}`,
        component:<BrandAmbassdorShip masterlistType={masterlistType}  masterlist={masterlist}/>,

      },
    ];
  }

  const [tabPanelData, setTabPanelData] = useState([]);
  useEffect(() => {
    if (masterlist) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlist, masterlistType,dataCount]);

  return (
    <Stack height={"100%"}>
      <Container maxW="auto" px={{ base: "4", md: "6" }} mx={"0"}>
        <Stack px={{ base: "0", md: "8" }}>
          {bookletAlias ? (
            <Box
              height={{ base: "max-content", md: "calc(100vh - 110px)" }}
              px="0px"
            >
              <BookletSongs
                handleImageClick={handleImageClick}
                masterlist={masterlist}
                masterlistType={masterlistType}
              />
            </Box>
          ) : (
            <TabMain  paddingTop="10px" gap="50px" width={"1080px"} cla pos={"fixed"}
            panelTop={{ base: "65px", sm: "65px", md: "65px" }} topTogleIcon="25px" listId={"listgridbutton6"} flag={flag} data={tabPanelData} />
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
