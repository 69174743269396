import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LeftVmenu from "../../FunctionalComponents/LeftVmenu";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { MdHomeFilled } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import * as getData from "../../services/Search";

import { FilterButton } from "../../CommonComponents/FilterButton";
import { useQuery } from "../../util/helper";
import InfiniteScroll from "react-infinite-scroll-component";
import { CopyPasteService } from "../../services/CopyPaste";
import { common } from "../../constants/constants";
import NoMoreToLoad from "../../CommonComponents/NoMoreToLoad";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholder = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/EconomicsofArts/placeholder.png`;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export default function ViewAll() {
  return (
    <SidebarWithHeader>
      <Stack width={"100%"} height={"100%"}>
        <SearchResultsDetailView />
      </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      handleNavigation={handleNavigation}
      LeftVmenuData={LeftVmenuData}
    />
  );
};

const SearchResultsDetailView = () => {
  const query = useQuery();
  const searchTerm = query.get("query");
  const type = query.get("type");
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [searchData, setSearchData] = useState([]);
  //future use
  // const [facets, setFacets] = useState([]);
  // const [sort, setSort] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [filterOptions] = useState({
    search: "",
    filters: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  useEffect(() => {
    const getSearchByTypeData = async () => {
      setIsLoading(true)
      const result = await getData.getSearchByTypeData(
        searchTerm,
        type,
        filterOptions,
        page
      );
      setIsLoading(false)
      if (result) {
        setSearchData(result.data);
        setData(result.data[0].hits);
        setPage((prevPage) => prevPage + 1);
      }
    };

    getSearchByTypeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, type]);
  const fetchMoreData = async () => {
    try {
      const result = await getData.getSearchByTypeData(
        searchTerm,
        type,
        filterOptions,
        page
      );

      if (result.data[0].hits) {
        setData((prevData) => [...prevData, ...result.data[0].hits]);
        setPage((prevPage) => prevPage + 1);
        //future use
        // const formattedFacets = result?.facetsHeaders?.map(header => {
        //   const key = Object.keys(header)[0];
        //   const display = header[key];
        //   const value = result?.facets[0][key]; // Assuming facetsData has only one object

        //   return {
        //     key,
        //     display,
        //     value
        //   };
        // });
        // setFacets(formattedFacets);
        if (page > result.data[0].totalPages-1) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false)

    }
  };
  return (
    <>
    {!isLoading?
    <Stack flexDirection={"row-reverse"}>
       <FilterButton
            paddingTop={"8"}
            onClick={handleFilterClick}
            marginRight={"0px"}
          />
      <Container  px={{ base: "15px", md: "64px" }} py="32px" maxW="auto" className="songs">
        <HStack pb="4">
          <Text
            fontSize={"30px"}
            fontWeight={700}
            lineHeight={"28px"}
            color="color.primary"
          >
            {searchData.length > 0 &&
              `Masterlists (${searchData[0].count})`}
          </Text>
        </HStack>
        <Stack py={4} flexDirection={"row"}>
          <HStack
            flexDirection={"row"}
            gap={4}
            justifyContent="center"
          >
            {data && data?.length > 0 ? (
              <HStack>
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    <Box textAlign="center" marginLeft={"50%"}>
                      <Stack marginTop={"20px"}>
                        <img
                          src={loaderImg}
                          width={"100px"}
                          height={"100px"}
                          alt="Loading"
                        />
                      </Stack>
                    </Box>
                  }
                  endMessage={<NoMoreToLoad/>}
                >
                  <SimpleGrid
                    columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                    spacing={{ base: "5px", md: "24px" }}
                  >
                    {data.length > 0 &&
                      data?.map((item, index) => (
                        <Box height="auto">
                          <Link to={`${item?.route}`}>
                            <Box
                              justifyItems="center"
                              display="flex"
                              height={"270px"}
                              bg="white"
                              justifyContent="space-around"
                            >
                              <Image
                                src={item?.image}
                                alt=""
                                cursor={"pointer"}
                                transition="1s ease"
                                width={"auto"}
                                objectFit={"contain"}
                                fallbackSrc={placeholder}
                              />
                            </Box>
                          </Link>
                          <Text
                            fontSize={"14px"}
                            fontWeight={"400px"}
                            lineHeight={"17px"}
                            textAlign="left"
                            color="#035DA1"
                            py="4"
                          >
                            {item?.title}
                          </Text>
                        </Box>
                      ))}
                  </SimpleGrid>
                </InfiniteScroll>
              </HStack>
            ) : (
              <>
                <HStack
                  onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
                  margin={"auto"}
                >
                  <Text
                    py={"40"}
                    fontSize={"45px"}
                    fontWeight="700"
                    textAlign="center"
                  >
                    {common.NO_DATA}
                  </Text>
                </HStack>
              </>
            )}
            <HStack
              className="mt-0"
              marginRight={"-15px!important"}
              alignItems={"flex-start"}
            >
              {/* {showFilterBox && (
           
          )} */}
            </HStack>
          </HStack>
        </Stack>
      </Container>
    </Stack>
    
  :
  (
    <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
        <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
    </HStack>
  )
  }
    </>
  );
};
