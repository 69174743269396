// EconomicsOfCinema
import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { explore } from "../../../constants/constants";
import { useNavigate, useParams } from "react-router-dom";
import { LibraryAndArchive } from "../../../components/ResearchCentre/LibraryAndArchive";
import H1 from "../../../Theme/Typography/H1";

export default function ResearchCentre({ children,marginX,pb,width,listId,right, className }) {
  const { alias } = useParams();
  const winnerName = alias;
  const [bookFlag, setBookFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!window.location.pathname.includes("research-centre"))
      navigate(`research-centre/library`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <Box bg="white">
        {children}
        <HStack>
          <H1 pb={pb} fontSize="30px" lineHeight="28.8px">
            {explore.RESEARCH_CENTRE}
          </H1>
        </HStack>
        <LibraryAndArchive
          masterlist={winnerName}
          bookFlag={bookFlag}
          setBookFlag={setBookFlag}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          masterlistType={"generic"}
          marginX={marginX}
          width={width}
          listId={listId}
          right={right}
          className={className}
          filerStyle={"box-filter-film2"}
        />
      </Box>
    </Box>
  );
}