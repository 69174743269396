import React, { useEffect, useState, useRef } from "react";
import * as getBrandAmbassdorShip from "./../../../../services/BrandAmbassdorShip";
import { Box, Container, HStack, Stack } from "@chakra-ui/react";
import { CopyPasteService } from "../../../../services/CopyPaste";
import Grid from "../../../../FunctionalComponents/SimpleGrid/Grid";
import ImageWithCitation2 from "../../../../FunctionalComponents/SimpleGrid/ImageWithCitation2";
import { FullScreenImage } from "../../../../FunctionalComponents/FullScreenImage";
import { DataNotFound } from "../../../../CommonComponents/DataNotFound";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../../CommonComponents/NoMoreToLoad";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

export default function BrandAmbassdorShip({masterlistType, masterlist }) {
  const [brandAmbassdorShipData, setBrandAmbassdorShipData] = useState([{}]);

  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const initialLoadComplete = useRef(false);

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const citationFactor = (data) => {
    const findItem = (items, key) => items.find(it => it.key === key) || {};

    return data.map((item, index) => {
      const dateOfArtwork = findItem(item, "date_of_artwork");
      const dateOfArtworkData = dateOfArtwork?.value ? dateOfArtwork : "";
      const creatorName = findItem(item, "creator_name");
      const company = creatorName?.value ? creatorName : "";
      const publishedIn = findItem(item, "published_in");
      const publishedInData = publishedIn?.value ? publishedIn : "";
      const personalityFeat = findItem(item, "personality_featured");
      const personalityFeatData = personalityFeat?.value ? personalityFeat : "";
      const fullImage = findItem(item, "direct_url_of_cover_image").value || "";

      return {
        desc: item.find((it) => it.key === "bibliographic_citation")?.value ? item.find((it) => it.key === "bibliographic_citation")?.value : "",
        image: fullImage ? fullImage : "",
        designerdesc: item.find((it) => it.key === "bibliographic_caption")?.value ? item.find((it) => it.key === "bibliographic_caption")?.value : "",
        content: item.find((it) => it.key === "description")?.value ? item.find((it) => it.key === "description")?.value : "",
        fullImg: fullImage ? fullImage : "",
        designerData: [dateOfArtworkData, company, publishedInData, personalityFeatData],
        link: item?.link
      };
    });
  };

    const getData = async () => {
      const result = await getBrandAmbassdorShip.BrandAmbassdorShipData(
        masterlistType, 
        masterlist,
        page
      );

      if (result && result?.data?.length > 0) {
        setBrandAmbassdorShipData(citationFactor(result?.data));
        if (!initialLoadComplete.current) {
          setPage((prevPage) => prevPage + 1);
          initialLoadComplete.current = true;
        }
      }
    };

    useEffect(() => {
      getData();
      // eslint-disable-next-line
    }, []);
    const fetchMoreData = async () => {
      try {
            const result = await getBrandAmbassdorShip.BrandAmbassdorShipData(
              masterlistType, 
              masterlist,
              page
            );
    
            if (result && result?.data) {
              const processedData = citationFactor(result?.data);
              setBrandAmbassdorShipData((prevData) => [...prevData, ...processedData]);
              setPage(prevPage => prevPage + 1);
  
              if (brandAmbassdorShipData.length >= result?.total) {
                setHasMore(false);
              }            
            }
          } catch (error) {
              console.error('Error fetching data:', error);
        }
    }

    const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };

  return (
    <>
    {brandAmbassdorShipData && brandAmbassdorShipData?.length !== 0 ? (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      <Container
        maxW="auto"
        className="songs"
        px={{ base: "4", md: "0px" }}
        py="25px"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {brandAmbassdorShipData && brandAmbassdorShipData.length > 0 && (
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
                 <InfiniteScroll
                  dataLength={brandAmbassdorShipData.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<Box textAlign="center" marginLeft={"50%"}>
                  <Stack marginTop={"20px"}>
                     <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                  </Stack>
                </Box>}
                  endMessage={<NoMoreToLoad/>}
                  >
              <Grid
                data={brandAmbassdorShipData}
                columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                spacing={{ base: "5px", md: "24px" }}
                handleImageClick={handleImageClick}
                component={ImageWithCitation2}
              />
              </InfiniteScroll>
            </HStack>
          )}
        </Stack>
      </Container>
      {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={true}
          title="banner"
          data={brandAmbassdorShipData}
        />
      )}
    </Stack>
    ):(
      <Stack alignItems={"center"} height={"100vh"}>
      <DataNotFound />
    </Stack>
    )}
    </>
  );
}
