import { Text } from "@chakra-ui/react";
import React from "react";

const H4 = ({ children, fontWeight="400", fontSize="16px", lineHeight, ...props }) => {
  return (
    <Text
      as="h4"
      {...props}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      textStyle={"primary"}
    >
      {children}
    </Text>
  );
};
export default React.memo(H4);