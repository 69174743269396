import {
  Box,
  Image,
  Stack,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper";
import { ImagePopupModal } from "../PopupModal/ImagePopupModal";

export default function BookSlider({
  setSelectedImg,
  selectedImg,
  bookData,
  left,
  width,
  thumbnailWidth,
}) {
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [bookFullScreenImage, setBookFullScreenImage] = useState(false);
  const [bookImgData, setBookImgData] = useState(null);
  const swiperRef = useRef(null);

  const showBookImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setBookFullScreenImage(flag);
      setBookImgData(image);
    } else {
      setBookFullScreenImage(flag);
      setBookImgData(null);
    }
  };
  const handleNextButtonClick = () => {
    const activeIndex = selectedImg.index + 1;
    const nextSlide = bookData[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide,
        desc: nextSlide.desc,
        index: activeIndex,
      });
    }
  };

  const handlePreviousButtonClick = () => {
    const activeIndex = selectedImg.index - 1;

    const nextSlide = bookData[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide,
        desc: nextSlide.desc,
        index: activeIndex,
      });
    }
  };
  return (
    <>
      <Box width={"100%"} height={{ base: "100%", md: "450px" }} display={"flex"} justifyContent={"center"}>
        <Image
          src={selectedImg.image}
          width={"auto"}
          height={"inherit"}
          objectFit={"contain"}
          cursor={"pointer"}
          onClick={() =>
            showBookImages(selectedImg, true, selectedImg.index)
          }
        ></Image>
      </Box>
      <Stack width={"100%"} position={"relative"} id="allBookSliders">
        <Swiper
          ref={swiperRef}
          grabCursor={true}
          navigation={true}
          modules={[EffectFade, Navigation, Pagination]}
          style={{ marginTop: "30px" }}
          slidesPerView={4}
          slidesPerGroup={1}
          onSlideNextTransitionEnd={() => {
            handleNextButtonClick();
          }}
          onSlidePrevTransitionEnd={() => {
            handlePreviousButtonClick();
          }}
        >
          {bookData?.length > 0 &&
            bookData?.map((item, index) => {
              return (
                <SwiperSlide>
                  <Stack position={"relative"} className="mt-0">
                    <Box width={"117px"} height={"73px"} display={"flex"} border={"1px"} borderColor={"color.lightgray4"} justifyContent={"center"}>
                    <Image
                      height={"inherit"}
                      objectFit={"contain"}
                      width={thumbnailWidth?thumbnailWidth:"100%"}
                      src={item}
                      onClick={() => {
                        swiperRef.current.swiper.slideTo(
                          swiperRef.current.swiper.activeIndex
                        );
                        setSelectedImg({
                          image: item,
                          desc: item.desc,
                          index: index,
                        });
                      }}
                    />
                    </Box>       
                    {swiperRef !== null && index !== selectedImg.index && (
                      <Stack
                        className="mt-0"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: left ? left : "21px",
                          width: width ? width : "60px",
                          height: "105px",
                          background: "rgba(0, 0, 0, 0.8)",
                        }}
                        onClick={() => {
                          swiperRef.current.swiper.slideTo(
                            swiperRef.current.swiper.activeIndex
                          );
                          setSelectedImg({
                            image: item,
                            desc: item.desc,
                            index: index,
                          });
                        }}
                      />
                    )}
                  </Stack>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Stack>
      {bookData?.length > 0 && (
        <ImagePopupModal
          isOpen={bookFullScreenImage}
          onClose={() => showBookImages(null, false)}
          imageData={bookImgData}
          data={bookData}
          clickedImageIndex={clickedImageIndex}
        />
      )}
    </>
  );
}
