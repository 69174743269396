import { Box, Heading, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
//import { pagination } from "../util/helper";
import "react-vertical-timeline-component/style.min.css";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

function NewallSongs({ item, type }) {
  return (
    <Stack
      borderWidth="1px"
      w={{ sm: "100%", md: "100%" }}
      height={{ sm: "376px", md: "14rem" }}
      direction={{
        base: "column",
        md: type === "reverse" ? "row-reverse" : "row",
      }}
      bg={"white"}
      width={"100%"}
    >
      <Stack flex={1} justifyContent="center" bg="black">
        <Box bg="black" alignItems={"center"}>
          <Image
            boxSize="auto"
            w="100%"
            objectPosition="center"
            src={
              item?.image
                ? `${IMAGE_BASE_PATH}/` +
                  item?.image
                : "https://d3f7r9v35rq5qh.cloudfront.net/ui/DummyImage/dummy.png"
            }
            alt=""
          />
        </Box>
      </Stack>
      <Stack
        flex={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="left"
        p={1}
        pt={2}
        className={type === "reverse" ? "arrow-right" : "arrow-left"}
      >
        <Stack
          height={"200px"}
          className={type === "reverse" ? "text-right" : "text-left"}
        >
          <Heading fontSize={"12px"} color={"gray.900"} as="i">
            {item?.id} - {item?.name}
          </Heading>
          <Text as="u" fontSize="12px" marginTop={"0.1rem"}>
            {item?.film_id?.name} {new Date(item?.date).getFullYear()}
          </Text>
          <Text
            fontSize={"12px"}
            lineHeight={"1.1rem"}
            fontWeight={"900"}
            color={"teal.500"}
            marginTop={"0.1rem"}
          >
            {item?.director}
          </Text>
        </Stack>
        <Heading
          className={type === "reverse" ? "date-right" : "date-left"}
          fontSize={"12px"}
        >
          {/* <Text fontSize="13px" fontWeight={"500"} marginTop={"0.1rem"}>
            {item?.music_directors[0].film_personality.name} |{" "}
            {item?.lyricists[0]?.film_personality.name} |{" "}
            {item?.playback_singers[0]?.film_personality.name}
          </Text> */}
        </Heading>
      </Stack>
    </Stack>
  );
}
export default NewallSongs;
