import React from 'react';
import { Box, Button, HStack, Stack, Input, Select, Container } from '@chakra-ui/react';
import H1 from '../../Theme/Typography/H1';
import Large from '../../Theme/Typography/Large';
import { personalityAttribute, userRegistrationForm } from '../../constants/constants';
import H2 from '../../Theme/Typography/H2';
import H3 from '../../Theme/Typography/H3';
import { ChevronDownIcon } from '@chakra-ui/icons';

const Step4 = ({ formData, setFormData, previousStep, confirm, selectedValues, setSelectedValues, handleSubmit }) => {
  confirm = () => {
    handleSubmit();
  };
  function getDetailsByAlias(data, alias,type) {
    const result = {};    
    const aliasEntry = data.filter((it) => (it.find(entry => entry.key === 'alias' && entry.value === alias)));

    if (aliasEntry) {
      const keysToFind = ['name', 'value', 'relevance_to_self_discovery_journey'];
      
      keysToFind.forEach(key => {
        const entry = aliasEntry[0].find(item => item.key === key);
        if (entry) {
          result[key] = entry.value;
        }
      });
    }
    return `${result?.value} | ${result?.name} ${type ? ` | ${result?.relevance_to_self_discovery_journey}`:``}`;
  }
  return (
    <Stack overflowY={"scroll"}>
      <Container maxW={{base:"auto",md:"760px"}} position={"relative"}>
        <Stack height={"100vh"} px={{ base: "0px", md: "62px" }} py="25px">
          <HStack pb="10px" justifyContent={"space-between"}>
            <Large fontSize={{base:"24px",md:"34px"}} lineHeight={{base:"28.92px",md:"35.92px"}} fontWeight="600">{userRegistrationForm.LOGIN_FORM_TITLE}</Large>
          </HStack>
          <H1 fontSize={{base:"18px",md:"24px"}} lineHeight={{base:"24.92px",md:"28.92px"}} fontWeight="400">{userRegistrationForm.ALMOST_DONE_FORM}</H1>

          <Stack width={{ base: "100%", md: "565px" }} spacing={4} pt="4" pb="40px">
           <Box>
              <H2 py="1" fontSize="18px" fontWeight={"600"}>{userRegistrationForm.REGISTRATION_FORM_FIELD_NAME_LABEL}</H2>
              <Input className="inputfield" value={formData.username} isReadOnly />
            </Box>
            <HStack spacing={{base:"2",md:"8"}}>
              <Box>
                <H2 py="1" fontSize="18px" fontWeight={"600"}>{userRegistrationForm.FIRST_NAME}</H2>
                <Input className="inputfield" width={{ base: "100%", md: "265px" }}  value={formData.firstName} isReadOnly />
              </Box>
              <Box>
                <H2 py="1" fontSize="18px" fontWeight={"600"}>{userRegistrationForm.LAST_NAME}</H2>
                <Input className="inputfield"  width={{ base: "100%", md: "265px" }} value={formData.lastName} isReadOnly />
              </Box>
            </HStack>
            <Box>
              <H2 py="1" fontSize="18px" fontWeight={"600"}>{userRegistrationForm.EMAIL}</H2>
              <Input className="inputfield" value={formData.email} isReadOnly />
            </Box>
            <HStack spacing={{base:"2",md:"8"}}>
            <Box>
              <H2 py="1" fontSize="18px" fontWeight={"600"}>{userRegistrationForm.AGE}</H2>
              <Select  icon={<ChevronDownIcon boxSize={8} fontSize={"32px"} />} className="inputfield" width={{ base: "150px", md: "265px" }}  value={formData.age} isReadOnly>
                <option value="16-29">16-29</option>
                <option value="30-45">30-45</option>
                <option value="46-60">46-60</option>
                <option value="60+">60+</option>
              </Select>
            </Box>
            <Box>
                <H2 py="1" fontSize="18px" fontWeight={"600"}>{personalityAttribute.NATIONALITY}</H2>
                <Input className="inputfield" width={{ base: "100%", md: "265px" }}  value={formData.nationality} isReadOnly />
              </Box>
            </HStack>
            <Box>
              <H2 py="1" fontSize="18px" fontWeight={"600"}>{userRegistrationForm.EDUCTION_1}</H2>
              <Input className="inputfield" value={formData.education1} isReadOnly />
            </Box>
            {formData.education2 !=='' && <Box>
              <H2 py="1" fontSize="18px" fontWeight={"600"}>{userRegistrationForm.EDUCTION_2}</H2>
              <Input className="inputfield" value={formData.education2} isReadOnly />
            </Box>}
            {formData.education3 !=='' &&<Box>
              <H2 py="1" fontSize="18px" fontWeight={"600"}>{userRegistrationForm.EDUCTION_3}</H2>
              <Input className="inputfield" value={formData.education3} isReadOnly />
            </Box>}

            {(selectedValues) ?
              <Box>
                <H2 py="1" fontSize="18px" fontWeight={"700"}>{userRegistrationForm.RESEARCH_CATEGORYS}</H2>
                <H3 fontSize={{base:"14px",md:"16"}} fontWeight='500' py={{base:"8px",md:"12px"}}  lineHeight={'18.78px'}>{getDetailsByAlias(selectedValues?.researchCategories,selectedValues?.researchCategoriesMapping?.core?.alias,true)}</H3>
                <H3 fontSize={{base:"14px",md:"16"}} fontWeight='500' py={{base:"8px",md:"12px"}} lineHeight={'18.78px'}>{getDetailsByAlias(selectedValues?.researchCategories,selectedValues?.researchCategoriesMapping?.material?.alias,true)}</H3>
                <H3 fontSize={{base:"14px",md:"16"}} fontWeight='500' py={{base:"8px",md:"12px"}}  lineHeight={'18.78px'}>{getDetailsByAlias(selectedValues?.researchCategories,selectedValues?.researchCategoriesMapping?.critical?.alias,true)}</H3>
                <H3 fontSize={{base:"14px",md:"16"}} fontWeight='500' py={{base:"8px",md:"12px"}}  lineHeight={'18.78px'}>{getDetailsByAlias(selectedValues?.researchCategories,selectedValues?.researchCategoriesMapping?.meaningful?.alias,true)}</H3>
              </Box>
              : ''
            }

            <Box pb={{base:'85px',md:"4"}}>
              <H2 py="1" fontSize="18px" fontWeight={"600"}>{userRegistrationForm.MASTERLISTS}</H2>
              <HStack> 
                <H3 fontSize={{base:"14px",md:"16"}} fontWeight='600' py={`12px`} lineHeight={'18.78px'}>{getDetailsByAlias(selectedValues?.researchCategories,selectedValues?.researchCategoriesMapping?.core?.alias)}</H3>
              </HStack>
              <Stack width={{ base: "100%", md: "480px" }}  height={{base:"150px",md:"190px"}} overflowY={"scroll"} >
                {
                  selectedValues?.coreMasterlist && selectedValues?.coreMasterlist?.length>0 &&
                  selectedValues?.coreMasterlist?.map((item,index)=>{
                    return(
                      <H3 fontSize={{base:"14px",md:"16"}} fontWeight='500' mt={`0px !important`} py={{base:"8px",md:"12px"}} lineHeight={'18.78px'} key={index}>{(item.split(":")[1])}</H3>
                    )
                  })
                }
              </Stack>
              <HStack pt="4"> 
                <H3 fontSize={{base:"14px",md:"16"}} fontWeight='600' pb={`12px`} lineHeight={'18.78px'}>{getDetailsByAlias(selectedValues?.researchCategories,selectedValues?.researchCategoriesMapping?.material?.alias)}</H3>
              </HStack>
              <Stack width={{ base: "100%", md: "480px" }} height={{base:"150px",md:"190px"}} >
                {
                  selectedValues?.materialMasterlist && selectedValues?.materialMasterlist?.length>0 &&
                  selectedValues?.materialMasterlist?.map((item,index)=>{
                    return(
                      <H3 fontSize={{base:"14px",md:"16"}} fontWeight='500' mt={`0px !important`} py={{base:"8px",md:"12px"}} lineHeight={'18.78px'}key={index}>{(item.split(":")[1])}</H3>
                    )
                  })
                }
              </Stack>
              <HStack> 
                <H3 fontSize={{base:"14px",md:"16"}} fontWeight='600' pb={`12px`} lineHeight={'18.78px'}>{getDetailsByAlias(selectedValues?.researchCategories,selectedValues?.researchCategoriesMapping?.critical?.alias)}</H3>
              </HStack>
              <Stack width={{ base: "100%", md: "480px" }} height={{base:"150px",md:"190px"}}>
                {
                  selectedValues?.criticalMasterlist && selectedValues?.criticalMasterlist?.length>0 &&
                  selectedValues?.criticalMasterlist?.map((item,index)=>{
                    return(
                      <H3 fontSize={{base:"14px",md:"16"}} fontWeight='500' mt={`0px !important`} py={{base:"8px",md:"12px"}} lineHeight={'18.78px'} key={index}>{(item.split(":")[1])}</H3>
                    )
                  })
                }
              </Stack>
              <HStack> 
                <H3 fontSize={{base:"14px",md:"16"}} fontWeight='600' pb={`12px`} lineHeight={'18.78px'}>{getDetailsByAlias(selectedValues?.researchCategories,selectedValues?.researchCategoriesMapping?.meaningful?.alias)}</H3>
              </HStack>
              <Stack width={{ base: "100%", md: "480px" }} height={{base:"150px",md:"190px"}} >
                {
                  selectedValues?.meaningfulMasterlist && selectedValues?.meaningfulMasterlist?.length>0 &&
                  selectedValues?.meaningfulMasterlist?.map((item,index)=>{
                    return(
                      <H3 fontSize={{base:"14px",md:"16"}} fontWeight='500' mt={`0px !important`} py={{base:"8px",md:"12px"}} lineHeight={'18.78px'} key={index}>{(item.split(":")[1])}</H3>
                    )
                  })
                }
              </Stack>
            </Box>
          </Stack>
          
          <Box  position={"fixed"} width={{base:"100%",md:"auto"}} bg="white" pr={{base:"30px",md:"0px"}} bottom={{base:"8%",md:"0%"}} py="4">
          <HStack  width={{ base: "100%", md: "565px" }} justifyContent={{base:"center",md:"space-between"}}>
            <Button width={{ base: "100%", md: "auto" }} px="40px" variant={"custom"} onClick={confirm}><H1 fontSize={{base:"18px",md:"24px"}}>{userRegistrationForm.CONFIRM}</H1></Button>
            <Button width={{ base: "100%", md: "auto" }} px="40px" bg="color.lightbrown" onClick={previousStep}><H1 fontSize={{base:"18px",md:"24px"}}>{userRegistrationForm.GO_BACK}</H1></Button>
          </HStack>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Step4;
