import {
  Box,
  Container,
  HStack,
  Stack,
} from "@chakra-ui/react";

//Core files
import React from "react";

//Service files
import { CopyPasteService } from "../../../services/CopyPaste";

//Component files
import TimeLine from "../../../FunctionalComponents/TimeLine/TimeLine";
import TimelineWithCitation1 from "../../../FunctionalComponents/TimeLine/TimelineWithCitation1";
import { FilterBox } from "../../../FunctionalComponents/Filter/FilterBox";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../CommonComponents/NoMoreToLoad";
import { FilterButton } from "../../../CommonComponents/FilterButton";
import FilterPills from "../../../components/FilterPills/FilterPills";
import { DataNotFound } from "../../../CommonComponents/DataNotFound";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

export default function TabularView({
   winnerName,
   //new data 
   showFilterBox,
   handleFilterClick,
   data,
   fetchMoreData,
   hasMore,
   setShowFilterBox,
   setApply,
   explore,
   apply,
   handleCheckboxChange,
   handleSearch,
   filterOptions,
   filteroptionData,
   setFilterOptions,
   setSearchTerm,
   filterCount,
   searchTerm,
   handleReset,
   handleImageClick,
   setFilterPills,
   filterPills,
   filteredResultCount
  }) {
 
  return (
   <>
   <Stack flexDirection={"row-reverse"}>
        {!showFilterBox && <FilterButton marginRight={"-30px"} onClick={handleFilterClick} />}
        <Container
          maxW="auto"
          className="songs"
          px={{ base: "4", md: "0px" }}
          py={4}
          position={"relative"}
        >
          <Stack flexDirection={"row"} gap={4} justifyContent="space-between">

            {data && data.length > 0 ? (
              <Stack
                flexDirection={"column"}
                gap={0}
                height="100%"
                onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/0`)}
                width={"95%"}
              >
                <InfiniteScroll
                 className="mt-0"
                 dataLength={data.length}
                 next={fetchMoreData}
                 hasMore={hasMore}
                  loader={
                    <Box textAlign="center" marginLeft={"50%"}>
                      <Stack marginTop={"20px"}>
                        <img
                          src={loaderImg}
                          width={"100px"}
                          height={"100px"}
                          alt="Loading"
                        />
                      </Stack>
                    </Box>
                  }
                  endMessage={<NoMoreToLoad />}
                >
          {((filterPills &&  filterPills!==undefined && filterCount>0) || filterOptions?.search) &&
          <FilterPills
          filters={filterPills}
          setFilterOptions={setFilterOptions}
          setApply={setApply}
          setSearchTerm={setSearchTerm}
          setFilterPills={setFilterPills}
          filteredResultCount={filteredResultCount}
          />
          }
            <TimeLine
               data={data}
               handleImageClick={handleImageClick}
               component={TimelineWithCitation1}
            />
                </InfiniteScroll>
              </Stack>
            )
              : (
                <Stack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                margin={"auto"}
              >
                <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                {filterPills &&  filterPills!==undefined && filterCount>0&&
                  <FilterPills
                  filters={filterPills}
                  setFilterOptions={setFilterOptions}
                  setApply={setApply}
                  setFilterPills={setFilterPills}
                  />
        }
        </Box>
                <DataNotFound />
              </Stack>
              )}
            <HStack
              marginLeft={"4"}
              marginRight={"-30px!important"}
              className="mt-0"
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <FilterBox
                setShowFilterBox={setShowFilterBox}
                setApply={setApply}
                explore={explore}
                apply={apply}
                handleCheckboxChange={handleCheckboxChange}
                handleSearch={handleSearch}
                filterOptions={filterOptions}
                filteroptionData={filteroptionData}
                setFilterOptions={setFilterOptions}
                setSearchTerm={setSearchTerm}
                filterCount={filterCount}
                searchTerm={searchTerm}
                handleReset={handleReset}
                className="common-box-filter"
                />
              )}
            </HStack>
          </Stack>
        </Container>
      </Stack>
   </>
  );
}