import {
  Box,
  Button,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core files
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Service Files
import * as getPostDetailsData from "../../services/Post";

// Subsection Files
import { SocialMediaButton } from "../../CommonComponents/SocialMediaButton";
import H2 from "../../components/layouts/Typography/H2";
import Layout from "../../components/sections/SidebarLayout";
import { common } from "../../constants/constants";
import { formatName } from "../../util/helper";
import Medium from "../../Theme/Typography/Medium";
import { BsArrowsAngleContract } from "react-icons/bs";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`;

// Image files

function PostDetails() {
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [postDetailsData, setPostDetailsData] = useState([]);
  const { alias, postAlias } = useParams();
  const masterlistTitle = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];



  useEffect(() => {
    const getData = async () => {
      const result = await getPostDetailsData.getPost(
        masterlistType,
        masterlistTitle,
        postAlias
      );
      if (result) {
        setPostDetailsData(result[0]);
      }
    };
    window.scrollTo(0, 0);
    getData();
  }, [masterlistTitle, masterlistType, postAlias]);

  const navigate = useNavigate();
  const showFullImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  return (
    <Layout>
      <Container maxW={"1400px"}   px={{ base: "15px", md: "34px" }} id="allslider">
        {postDetailsData && (
          <Stack>
            <Stack
              width={"100%"}
              paddingBottom={{ base: 12, md: 4 }}
              alignItems={"center"}
            >
              <Stack width={"100%"} position={"relative"} id="postdetailslider">
                <Stack flexDirection={"column"}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    width={"100%"}
                    height={{ base: "100%", md: "533px" }}
                  >
                    <Image
                      src={postDetailsData?.image}
                      alt=""
                      onClick={(e) => {
                        showFullImages(postDetailsData?.image, true);
                      }}
                      fallbackSrc={placeholderImg}
                      width={"auto"}
                      objectFit={"contain"}
                      height={"100%"}
                      cursor={"pointer"}
                    />
                  </Box>
                </Stack>

                <Stack flexDirection={"row"} paddingY={{base:"15px",md:"8"}} className="row">
                  <HStack
                    alignItems={"flex-start"}
                    flexDirection={"column"}
                    width={{base:"100%",md:"771px"}}
                  >
                    <H2 pb="1">
                      {" "}
                      {postDetailsData?.title !== ""
                        ? postDetailsData?.title
                        : common.CONTENT_AWAITED}
                    </H2>
                    <Box
                    style={{
                      columnCount: {base:"1",md:"2"},
                      columnGap: "20px",
                      marginInlineStart:"0rem",
                    }}
                  >
                    <Medium paddingTop={{base:"10px",md:"25px"}} color="color.gray">
                    {postDetailsData?.description}
                    </Medium>
                  </Box> 

                  </HStack>
                  <HStack
                    alignItems={"flex-start"}
                    flexDirection={"column"}
                    width={{base:"100%",md:"495px"}}
                    px={{ base: "0", md: "4" }}
                  >
                    <HStack
                      py={{ base: "2", md: "0" }}
                      gap={"10px"}
                      wrap={"wrap"}
                    >
                      {postDetailsData?.insight_alias && (
                        <Button
                          className="keyword-btn blob-button"
                          marginInlineStart={"0rem!important"}
                          onClick={() =>
                            navigate(
                              `/${masterlistType}/${masterlistTitle}/insights/${postDetailsData?.insight_alias}`
                            )
                          }
                        >
                          {`INSIGHT`}
                        </Button>
                      )}

                      {postDetailsData?.masterlist_type &&
                        postDetailsData?.masterlist_type.map((it, index) => {
                          // const aliasValue = postDetailsData?.alias || "";
                          const masterlistAlias =
                            it?.alias || "";
                          const isPrimary =it?.alias===alias
                          return (
                            <Button
                              className={`keyword-btn ${
                                isPrimary ? "primary-color" : "secondary-color"
                              }`}
                              marginInlineStart={"0rem!important"}
                              onClick={() =>
                                navigate(`/${it.type}/${masterlistAlias}`)
                              }
                              width={"max-content"}
                            >
                              {it.name}
                            </Button>
                          );
                        })}
                      {postDetailsData?.keyword_masterlist_tag_alias &&
                        postDetailsData?.keyword_masterlist_tag_alias.map(
                          (it, index) => (
                            <Button
                              className={`keyword-btn ${
                                it?.link ? `keyword-color` : ``
                              }`}
                              onClick={() => navigate(`/generic/${it}/research-centre/all`)}
                              width={"max-content"}
                              marginInlineStart={"0rem!important"}
                            >
                              {formatName(it)}
                            </Button>
                          )
                        )}
                      {postDetailsData?.provisional_masterlists_tag_alias &&
                        postDetailsData?.provisional_masterlists_tag_alias.map(
                          (it, index) => (
                            <Button
                              as="span"
                              className={`keyword-btn`}
                              width={"max-content"}
                              marginInlineStart={"0rem!important"}
                            >
                              {formatName(it)}
                            </Button>
                          )
                        )}
                    </HStack>
                    <HStack
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                      marginInlineStart={"0rem!important"}
                      pb={{base:"4",md:"0"}}
                    >
                      <Text py="4" fontSize={"18px"} fontWeight={"600"}>
                        {common.SHARE}
                      </Text>
                      <SocialMediaButton />
                    </HStack>
                  </HStack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Container>
      <ImagePopupModal
        isOpen={fullScreenImage}
        onClose={() => showFullImages(null, false)}
        imageData={imgData}
      />
    </Layout>
  );
}
export default PostDetails;

const ImagePopupModal = ({ isOpen, onClose, imageData }) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent alignItems={"center"} zIndex={"999"}>
        <ModalBody>
          <Box bg="white">
            <Stack>
              <HStack justifyContent={"center"} marginTop={"20px"}>
                <Box width={"100%"} height={"auto"}></Box>
                <Image
                  src={imageData}
                  fallbackSrc={placeholderImg}
                  width={"100%"}
                  objectFit={"contain"}
                  height={"inherit"}
                ></Image>
              </HStack>
              <HStack justifyContent={"flex-end"}>
                <Text
                  fontSize={"xl"}
                  bg={"black"}
                  opacity={0.7}
                  color={"white"}
                  fontWeight={"400"}
                  padding="2"
                  borderRadius={"35px"}
                  width="max-content"
                 bottom={"10%"}
                 right={{base:"5%",md:"20%"}}
                  cursor="pointer"
                  position={"absolute"}
                  onClick={onClose}
                >
                  <BsArrowsAngleContract />
                </Text>
              </HStack>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
