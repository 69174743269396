import { bka_abdul_halim_the_lucknow_Omnibus_2001, bka_jindal_my_adventures_with_satyajit_ray_2017, bke_geeta_kapur_subramanyan_1987, bke_mukhopadhyay_pather_panchali_1984, davierwalla, sayed_haider_raza_modern_indian_painting, the_tale_of_the_talking_face } from "../MockData/BookData";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";

export const getResearchCentreBookbyBookAliasAPI = async ( bookAlias) => {
  const result = await api.get(`${APIS.BASE_URL}film/shatranj-ke-khilari-1977/research-centre/library/${bookAlias}/details`)
  return result?.data;
};

export const getResearchCentreBookbyBookAlias = async ( bookAlias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getResearchCentreBookbyBookAliasDummyData(bookAlias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getResearchCentreBookbyBookAliasAPI( bookAlias);
    try {
     return response;
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};
export const getResearchCentreBookbyBookAliasDummyData = async (bookAlias) => {
  const bookDataMap = {
    "bka-jindal-my-adventures-with-satyajit-ray-2017": bka_jindal_my_adventures_with_satyajit_ray_2017,
    "bke-a-sayed-haider-raza-modern-indian-painting":sayed_haider_raza_modern_indian_painting,
    "bke-geeta-kapur-subramanyan-1987":bke_geeta_kapur_subramanyan_1987,
    "bke-mukhopadhyay-pather-panchali-1984":bke_mukhopadhyay_pather_panchali_1984,
    "bka-abdul-halim-the-lucknow-Omnibus-2001":bka_abdul_halim_the_lucknow_Omnibus_2001,
    "the-tale-of-the-talking-face":the_tale_of_the_talking_face,
    "davierwalla":davierwalla

    // Add more cases as needed
  };
  // Check if the bookAlias exists in the map, if not, use a default value
  const bookData = bookDataMap[bookAlias] || bke_mukhopadhyay_pather_panchali_1984;
  return bookData;
};