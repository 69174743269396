import { Box, HStack, Image, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import H4 from "../../Theme/Typography/H4";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`

function ImageSlider({
  sliderData,
  handleImageClick,
  width,
  height,
  modules,
  grabCursor,
  setSwiper,
  classname,
  sheight,
  cursor,
}) {
  return (
    <Swiper
      grabCursor={!grabCursor && true}
      navigation={true}
      modules={modules}
      onSwiper={setSwiper && setSwiper}
    >
      {sliderData && sliderData?.map((item, index) => {
        return (
          <>
            <SwiperSlide key={item?.id} className={classname && classname}>
              <VStack alignItems={"flex-start"}>
                <Stack
                  width={{ base: "auto", md: "100%" }}
                  height={{ base: "100%", md: sheight ? sheight : "auto" }}
                >
                  <Box width={{ base: "100%", md: "100%" }} height={"400px"}>
                    <Image
                      onClick={() =>
                        handleImageClick && handleImageClick(item, index)
                      }
                      src={item.imgPath ? item?.imgPath : item}
                      width={"100%"}
                      height={"inherit"}
                      fallbackSrc={placeholderImg}
                      // objectFit={"contain"}
                      cursor={cursor}
                    />
                  </Box>
                </Stack>
                <HStack>
                <H4 textAlign={"left"} lineHeight={"18.78px"} color="color.blue">
                    {item?.desc ? item?.desc : item?.title}
                  </H4>
                </HStack>
              </VStack>
            </SwiperSlide>
          </>
        );
      })}
    </Swiper>
  );
}

export default ImageSlider;
