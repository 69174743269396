import { Box, Container, HStack, Image, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react'
import { Link } from 'react-router-dom';
import Small from '../../Theme/Typography/Small';
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholder = `${IMAGE_BASE_PATH}/ui/DummyImage/x-small.png`;

export default function SearchCar({data,searchsTerm}) {

  return (
    <Stack>
    <Container px="0" maxW="auto" className="songs">
      <Stack
      flexDirection={"row"} gap={4} justifyContent="center">
        <HStack alignItems={"flex-start"} width={"100%"} pt={{ base: "4", md: "0" }}>
            <>
          {data?.hits?.length > 0 &&
           data.hits.slice(0, 12).map((item, index) => (
            <VStack>
      <Stack key={index} alignItems={"center"} width="80px">
        <Link to={item?.type === "generic" ? `${item?.route}/research-centre/all` : `${item?.route}`}>
          <Box
            display="flex"
            justifyContent="center"
            height="62px"
            width="62px"
            bg="white"
            border="1px solid"
            borderColor="#f1f1f1"
          >
            <Image
              src={item?.image}
              alt={item?.title || 'Image'}
              cursor="pointer"
              transition="1s ease"
              width="auto"
              objectFit="contain"
              fallbackSrc={placeholder}
            />
          </Box>
        </Link>
        </Stack>
        <Stack>
        <Small
          textAlign="left"
          color="color.blue"
          lineHeight="16px"
          py="1"
          className="mt-0"
        >
          {item.quality === "primary" && (
            <Text as="span" className="primary-p-symbol">P - </Text>
          )}
          {item?.title}
        </Small>
        </Stack>
         </VStack>
           ))
          }     
            </>
          </HStack>
        </Stack>
      </Container>
    </Stack>
    )
}     