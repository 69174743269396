import React, { useEffect, useRef, useState } from "react";
import * as getEssayData from "./../../../../services/Essays.js";
import { Box, Container, HStack, Stack } from "@chakra-ui/react";
import { CopyPasteService } from "../../../../services/CopyPaste";
// import { FullScreenImage } from "../../Component/FullScreenImage";
import Grid from "../../../../FunctionalComponents/SimpleGrid/Grid";
import ImageWithCitation2 from "../../../../FunctionalComponents/SimpleGrid/ImageWithCitation2";
import { WebpageComingSoon } from "../../../../CommonComponents/WebpageComingSoon";
import { FullScreenImage } from "../../../../FunctionalComponents/FullScreenImage";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../../CommonComponents/NoMoreToLoad.js";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

export default function Essay({ masterlist }) {
  const [essayData, setEssayData] = useState([{}]);

  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const initialLoadComplete = useRef(false);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType=parts[1];

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
 
  const findItem = (items, key) => items.find(it => it.key === key) || {};

  const citationFactor = (data) => {
    return data.map((item) => {
      const fullImage = findItem(item, "direct_url_of_cover_image").value || "";
      const titleItem = findItem(item, "title");
      const author = findItem(item, "creator_name");
      const dateofArt = findItem(item, "sortable_year");
      const title_of_essay = titleItem.value ? titleItem : "";
  
      return {
        desc: item.find((it) => it.key === "bibliographic_citation")?.value ? item.find((it) => it.key === "bibliographic_citation")?.value : "",
        image: fullImage ? fullImage : "",
        designerdesc: item.find((it) => it.key === "bibliographic_caption")?.value ? item.find((it) => it.key === "bibliographic_caption")?.value : "",
        content: item.find((it) => it.key === "description")?.value ? item.find((it) => it.key === "description")?.value : "",
        fullImg: fullImage ? fullImage : "",
        designerData: [title_of_essay, author, dateofArt],
      };
    });
  };

  const getData = async () => {
    const result = await getEssayData.essayData(
      masterlistType,
      masterlist,
      page
    );

    if (result && result?.data.length > 0) {
      const processedData = citationFactor(result?.data);
      setEssayData(processedData);

      if (!initialLoadComplete.current) {
        setPage((prevPage) => prevPage + 1);
        initialLoadComplete.current = true;
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const fetchMoreData = async () => {
    try {
          const result = await getEssayData.essayData(
            masterlistType,
            masterlist,
            page
          );

          if (result && result?.data) 
          {
            const processedData = citationFactor(result?.data);
            setEssayData((prevData) => [...prevData, ...processedData]);
            setPage(prevPage => prevPage + 1);
              if (essayData.length >= result?.total) {
              setHasMore(false);
            }
          }
        }
        catch (error) 
        {
          console.error('Error fetching data:', error);
        }
  }

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };

  return (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      <Container
        maxW="auto"
        className="songs"
        px={{ base: "4", md: "0px" }}
        pb="8"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {essayData.length > 0 ? (
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
            <InfiniteScroll
              dataLength={essayData.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<Box textAlign="center" marginLeft={"50%"}>
              <Stack marginTop={"20px"}>
                  <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
              </Stack>
            </Box>}
              endMessage={<NoMoreToLoad/>}
              >
              <Grid
                data={essayData}
                columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                spacing={{ base: "5px", md: "24px" }}
                handleImageClick={handleImageClick}
                component={ImageWithCitation2}
              />
            </InfiniteScroll>
            </HStack>
          ) : (
            <WebpageComingSoon/>
          )}
        </Stack>
      </Container>
      {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={essayData}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="easayimages"
        />
      )}
    </Stack>
  );
}
