import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export default function ImageWithCitation({
  data,
  columns,
  spacing,
  height,
  fontSize,
  textStyle,
  fontWeight,
  setShowCarousal,
  setcarousalData,
  handleImageClick
}) {
  return (
    <>
      {data &&
        data?.map((it, index) => {
          return (
            <Box height="auto">
              {it.link ? (
                <>
                  <Box
                    height={"auto"}
                    bg="white"
                    justifyContent="space-around"
                    onClick={() => {
                      setShowCarousal(true);
                      setcarousalData(it?.fullImage);
                    }}
                  >
                    <Box display={"flex"} justifyContent={"center"} width={"100%"} height="221px">
                      <Image
                        src={it.image}
                        alt=""
                        height="100%"
                        objectFit={"contain"}
                      />
                    </Box>
                  </Box>
                  <Link  to={it.link} cursor={"pointer"}>
                    <Text
                      textAlign="left"
                      color="#0066B3"
                      p="4"
                      fontSize={"16px"}
                      fontWeight={"400"}
                    >
                      {it?.desc}
                    </Text>
                  </Link>
                </>
              ) : (
                <>
                     <Box display={"flex"} justifyContent={"center"} width={"100%"} height="221px">
                      <Image
                        src={it.image}
                        alt=""
                        height="100%"
                        objectFit={"contain"}
                      />
                    </Box>
                  <Text
                    textAlign="left"
                    color="#0066B3"
                    py="4"
                    cursor={"pointer"}
                    fontSize={"16px"}
                    fontWeight={"400"}
                    onClick={() => {
                      handleImageClick(it)
                    }}
                    >
                      {it?.desc}
                  </Text>
                </>
              )}
            </Box>
          );
        })}
    </>
  );
}
