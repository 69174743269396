import React, { useEffect, useRef, useState } from "react";
import {
  HStack,
  Image,
  Stack,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { createDataObject, totalFilterCount } from "../../../util/helper";
import GridView from "./Archive/GridView";
import TabularView from "./Archive/TabularView";
import * as getSearchArchiveData from "../../../services/Search";
import SortBy from "../../../components/ResearchCentre/Components/SortBy";
import { updateFacetsData } from "../../../util/helper";
import { FullScreenImage } from "../../../components/FullScreen/AuctionFullScreen";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export const Archive = ({
  visible,
  handleBookClick,
  setleftFlag,
  openDrawer,
  setOpenDrawer,
  tableName,
  masterlist,
  setLibraryCount,
  masterlistType="search",
  setArchiveCount,
  SearchTerm,
  filterbuttonStyle
}) => {
    const [showFilterBox, setShowFilterBox] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterOptions, setFilterOptions] = useState({ search: "", filters: {}, sortBy_v: "creator_surname",gate:"AND" });
    const [apply, setApply] = useState(false);
    const [clickedImageIndex, setClickedImageIndex] = useState(null);
    const [archiveFullScreenImage, setArchiveFullScreenImage] = useState(false);
    const [archiveImgData, setArchiveImgData] = useState(null);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const { tabValue,bookAlias } = useParams();
    const [filterCount, setFilterCount] = useState(0);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [filterPills, setFilterPills] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredResultCount, setFilteredResultCount] = useState(0);

    const initialLoadComplete = useRef(false);

    const navigate = useNavigate()
    const handleFilterClick = () => {
      setShowFilterBox(!showFilterBox);
    };
  
    //  Filter Execution starts
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
      handleCheckboxChange("searches", e.target.value)
    };
  
    const handleCheckboxChange = (value, type) => {
      let selectedArray = [];
      let selectedString = "";
  
      switch (value) {
        case "sort":
          selectedString = filterOptions.sortBy_v;
          break;
          case "gate":
            selectedString = filterOptions.gate;
            break;
        case "searches":
          selectedString = filterOptions.search;
          break;
        default:
          if (value in filterOptions.filters) {
            selectedArray = filterOptions.filters[value];
          }
          break;
      }
  
      if (selectedArray.includes(type)) {
        selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
      } else {
        selectedArray = [...selectedArray, type];
      }
  
      if (selectedString !== type) {
        selectedString = type;
      }
  
      switch (value) {
        case "sort":
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            sortBy_v: selectedString,
          }));
          break;
          case "gate":
            setFilterOptions(prevFilterOptions => ({
              ...prevFilterOptions,
              gate: selectedString,
            }));
            break;
        case "searches":
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            search: selectedString,
          }));
          break;
        default:
          if (value in filterOptions.filters) {
            setFilterOptions(prevFilterOptions => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                [value]: selectedArray,
              },
            }));
          }
          break;
      }
    };
  
    const citationFactor = (data) => {
      return data.map((item, index) => 
      {
        const title = createDataObject([item?.find((nestedItem) => nestedItem?.key === "title") ]);
        const medium = createDataObject([item?.find((nestedItem) => nestedItem?.key === "medium_artwork") ]);
        const lot_number = createDataObject([item?.find((nestedItem) => nestedItem?.key === "lot_number") ]);
        const auction_house = createDataObject([item?.find((nestedItem) => nestedItem?.key === "auction_house_name") ]);
        const venue_location = createDataObject([item?.find((nestedItem) => nestedItem?.key === "venue") ]);
        const date_of_auction = createDataObject([item?.find((nestedItem) => nestedItem?.key === "auction_year_of_event") ]);
        const type_of_auction = createDataObject([item?.find((nestedItem) => nestedItem?.key === "type_of_auction") ]);
  
        const number_of_bids_received = createDataObject([
          item?.number_of_bids_received,
        ]);
  
        const percentage_over_under_mid_estimate = createDataObject([
          item?.percentage_over_under_mid_estimate,
        ]);
  
        const artwork_square_inch_rate = createDataObject([
          item?.artwork_square_inch_rate,
        ]);
  
        const previous_auction_sales = createDataObject([
          item?.previous_auction_sales,
        ]);
  
        const cagr_based_on_previous_sale = createDataObject([
          item?.cagr_based_on_previous_sale,
        ]);
        const size = createDataObject([item?.size]);
  
        return {
        image: item?.find((nestedItem) => nestedItem?.key === "direct_url_of_cover_image")?.value || item?.find((nestedItem) => nestedItem?.key === "direct_url_of_preview_image")?.value || "",
        title: item?.find((nestedItem) => nestedItem?.key ===
          "bibliographic_citation")?.value ||
          item?.find((nestedItem) => nestedItem?.key ===
            "bibliograhpic_citation")?.value ||
          "",
          listDesc:item?.find((nestedItem) => nestedItem?.key ===
          "bibliographic_caption")?.value ||
          item?.find((nestedItem) => nestedItem?.key ===
            "bibliograhpic_caption")?.value ||
          "",
          publicationYear:item?.find((nestedItem) => nestedItem?.key ===
          "year")?.value,
        imagedesc: item?.find((nestedItem) => nestedItem?.key ===
          "bibliographic_caption")?.value || "",
        alias: item?.find((nestedItem) => nestedItem?.key ===
          "alias")?.value,
        index: index,
        fullImageTitle:item?.find((nestedItem) => nestedItem?.key === "creator_name")?.value,
        essayistData: [
          title,
          medium,
          lot_number,
          auction_house,
          venue_location,
          date_of_auction,
          type_of_auction,
          size,
        ],
        auctionData: [
          number_of_bids_received,
          percentage_over_under_mid_estimate,
          artwork_square_inch_rate,
          previous_auction_sales,
          cagr_based_on_previous_sale,
        ],
      }
    }
    );
    };
    const [facets, setFacets] = useState([]);
    const [sort, setSort] = useState([]);

    async function fetchData() {
      try {
        setIsLoading(true);
        const result = await getSearchArchiveData.searchResearchArchiveTab(SearchTerm, filterOptions, 1);
        setIsLoading(false);
        if (result && result?.data.length > 0) {
          const processedData = citationFactor(result?.data);
          setData(processedData);
          if (!initialLoadComplete.current) {
            setPage(page + 1);
            initialLoadComplete.current = true;
          }
          setArchiveCount(result?.total)
          if (result.total === result?.data.length) {
            setHasMore(false)
          }
          if (result?.facetsHeaders && result?.facets) {
            const formattedFacets = updateFacetsData(result);
            setFacets(formattedFacets);
          
            if (formattedFacets && formattedFacets.length > 0) {
              setFilterOptions((prevFilterOptions) => ({
                ...prevFilterOptions,
                filters: {
                  ...prevFilterOptions.filters,
                  ...formattedFacets.reduce((acc, facet) => {
                    acc[facet.key] = [];
                    return acc;
                  }, {}),
                },
              }));
            }
          }
          const sortedFacets = result?.sortByHeader?.map(obj => {
            const key = Object.keys(obj)[0];
            return { key: key, display: obj[key] };
          });
          setSort(sortedFacets);
        }else{
          setData([])
          setArchiveCount(0)
        }
  
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    }
    const handleResetPage=()=>{
      initialLoadComplete.current = false;
      setHasMore(true);
      setPage(1);
      setTriggerFetch(prev => !prev);
    }
    useEffect(() => {
      handleResetPage();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SearchTerm]);
    useEffect(() => {
      fetchData();
      setFilterPills(filterOptions)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerFetch]);
  
    const fetchMoreData = async () => {
      try {
        const result = await getSearchArchiveData.searchResearchArchiveTab(SearchTerm, filterOptions, page);
  
        if (result) {
  
          const processedData = citationFactor(result?.data);
          setData(prevData => [...prevData, ...processedData]);
          setPage(page + 1);
          const sortedFacets = result?.sortByHeader?.map(obj => {
            const key = Object.keys(obj)[0];
            return { key: key, display: obj[key] };
          });
          setSort(sortedFacets);
  
          if (data.length >= result?.total) {
            setHasMore(false);
          }
  
        }
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    async function fetchFilterData(page = 1) {
  
      try {
        setIsLoading(true);
        const result = await getSearchArchiveData.searchResearchArchiveTab(SearchTerm, filterOptions, page) || {};
        setIsLoading(false);
        if (result) {
          const processedData = citationFactor(result?.data);
          setData(processedData);
          setPage(prev=>prev+1)
          const sortedFacets = result?.sortByHeader?.map(obj => {
            const key = Object.keys(obj)[0];
            return { key: key, display: obj[key] };
          });
          setSort(sortedFacets);
          const filtercnt= totalFilterCount(filterOptions?.filters)
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }
          if (result?.data?.length >= result?.total) {
            setHasMore(false);
          }

          if (result?.facetsHeaders && result?.facets) {
            const formattedFacets = updateFacetsData(result);
            setFacets(formattedFacets);
          }
        }
  
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    }
    useEffect(() => {
  
      if (apply) {
        setApply(false);
        setHasMore(true);
        setPage(1)
        fetchFilterData();
        setFilterPills(filterOptions)

      }
      const filtercnt = totalFilterCount(filterOptions?.filters)
      setFilterCount(filtercnt)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apply]);
  
    const showArchiveImages = (image, item, flag, index) => {
      if (image) {
        setClickedImageIndex(index);
        setArchiveFullScreenImage(flag);
        setArchiveImgData(image);
        if(masterlistType==="search"){
          if (!window.location.pathname.includes(item?.alias)) {
            navigate(`/search/research-centre/archive/${item?.alias}?query=${encodeURIComponent(SearchTerm)}`)
          }
        }else{
          navigate(`/${masterlistType}/${SearchTerm}/research-centre/archive/${item?.alias}`);
          setArchiveFullScreenImage(flag);

        }
      } else {
        setArchiveFullScreenImage(flag);
        setArchiveImgData(null);
      }
  
    };
  
    const handleReset = () => {
      setFilteredResultCount(0);
      const initializedFilters = facets.reduce((acc, facet) => {
        acc[facet.key] = [];
        return acc;
      }, {});
      setFilterOptions({
        search: "",
        filters: initializedFilters
      });
      setFilterCount(0)
      setSearchTerm("");
    }
    //modal for archive
    useEffect(() => {
      if (data && bookAlias) {
        const item = data.find((it) => it.alias === bookAlias);
        if (item !== undefined && window.location.pathname.includes('archive')) {
          showArchiveImages(item?.image, item, true, item?.index);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
    }, [data, bookAlias]);

  return (
    <>
     {!isLoading ? 
    <Stack position={"relative"}>
      {sort && sort?.length>0 &&  
          <SortBy
          sort={sort}
          masterlistType={masterlistType}
          filterOptions={filterOptions}
          handleCheckboxChange={handleCheckboxChange}
          setApply={setApply}
          />
          }
      {visible ? (
        <>
          <GridView
              showFilterBox={showFilterBox}
              masterlistType={masterlistType}
              handleFilterClick={handleFilterClick}
              data={data}
              fetchMoreData={fetchMoreData}
              hasMore={hasMore}
              showArchiveImages={showArchiveImages}
              setShowFilterBox={setShowFilterBox}
              setApply={setApply}
              facets={facets}
              filterPills={filterPills}
              setFilterPills={setFilterPills}
              sort={sort}
              handleCheckboxChange={handleCheckboxChange}
              handleSearch={handleSearch}
              filterOptions={filterOptions}
              filteroptionData={facets}
              filterCount={filterCount}
              setFilterOptions={setFilterOptions}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              handleReset={handleReset}
              archiveFullScreenImage={archiveFullScreenImage}
              archiveImgData={archiveImgData}
              clickedImageIndex={clickedImageIndex}
              SearchTerm={SearchTerm}
              filterbuttonStyle={filterbuttonStyle}
              filteredResultCount={filteredResultCount}
          />
        </>
      ) : (
        <>
          <TabularView
            showFilterBox={showFilterBox}
            masterlistType={masterlistType}
            handleFilterClick={handleFilterClick}
            data={data}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            showArchiveImages={showArchiveImages}
            setShowFilterBox={setShowFilterBox}
            setApply={setApply}
            facets={facets}
            sort={sort}
            filterPills={filterPills}
            setFilterPills={setFilterPills}
            handleCheckboxChange={handleCheckboxChange}
            handleSearch={handleSearch}
            filterOptions={filterOptions}
            filteroptionData={facets}
            filterCount={filterCount}
            setFilterOptions={setFilterOptions}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            handleReset={handleReset}
            archiveFullScreenImage={archiveFullScreenImage}
            archiveImgData={archiveImgData}
            clickedImageIndex={clickedImageIndex}
            SearchTerm={SearchTerm}
            filterbuttonStyle={filterbuttonStyle}
            filteredResultCount={filteredResultCount}
          />
        </>
      )}
    </Stack>:
      (
        <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
            <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
        </HStack>
      )
    }
     {archiveFullScreenImage && tabValue==="archive" &&  (
        <FullScreenImage
          isOpen={archiveFullScreenImage}
          onClose={(item) => {
            showArchiveImages(null, false);
            setArchiveFullScreenImage(false);
            if(tabValue==="archive" && masterlistType==="search"){

            if (!(SearchTerm===item?.alias)) {
              navigate(-1)
            }
          }
            if(masterlistType==="search"){
              navigate(`/${masterlistType}/research-centre/archive?query=${encodeURIComponent(SearchTerm)}`);
            }else{
              navigate(`/${masterlistType}/${SearchTerm}/research-centre/archive`);
              
            }
          }}
          imageData={archiveImgData}
          data={data}
          clickedImageIndex={clickedImageIndex}
          imageHeight={{ base: "100%", md: "90vh" }}
        />
      )}
    </>
  );
};