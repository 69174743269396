// TODO: Need to use this utility for image, committed added for now
// Once confirm with Robin will use it
//
//

import _ from "lodash";

export const getImageURL = (URI, width, height) => {

  if (
    !URI ||
    (!_.isString(URI) && !_.isArray(URI)) ||
    (_.isObject(URI) && _.isEmpty(URI))
  ) {
    return false // Replace with a valid default image URL
  }

  const url = _.isArray(URI) ? URI[0] : URI;

  if (url.indexOf("https://") !== -1 || url.indexOf("http://") !== -1) {
    return url;
  }

  const imageURI = `${process.env.REACT_APP_MONGODB_URL}images/${url}`;

  return imageURI;

// Add URl and params and add this to image resize API

};
