import { Box, Container, HStack, Stack} from "@chakra-ui/react";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import { explore } from "../../constants/constants";
import { CopyPasteService } from "../../services/CopyPaste";
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "../../FunctionalComponents/SimpleGrid/Grid";
import ArchiveGrid from "../../FunctionalComponents/SimpleGrid/ArchiveGrid";
import { FilterBox } from "../../FunctionalComponents/Filter/FilterBox";
import CommonDrawer from "../../FunctionalComponents/CommonDrawer/CommonDrawer";
import DrawerHeader from "../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerHeader";
import DrawerBody from "../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerBody";
import DrawerFooter from "../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerFooter";
import { FilterButton } from "../../CommonComponents/FilterButton";
import NoMoreToLoad from "../../CommonComponents/NoMoreToLoad";
import { DataNotFound } from "../../CommonComponents/DataNotFound";
import FilterPills from "../FilterPills/FilterPills";
import ContentModal from "../../pages/Film/ResearchCentre/Library/ContentModal";
import SliderModal from "../../pages/Film/ResearchCentre/Library/SliderModal";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

function GridView({
  handleBookClick,
  contentClicked,
  scanClicked,
  setleftFlag,
  setContentClicked,
  setScanClicked,
  openDrawer,
  setOpenDrawer,
  tableName,
  masterlist,
  imageTitle,
  masterlistType = "generic",
  setLibraryCount,
  //new data props
  fetchMoreData,
  data,
  drawerBook,
  handleClose,
  showFilterBox,
  handleFilterClick,
  hasMore,
  handleDrawer,
  setShowFilterBox,
  setApply,
  sort,
  handleCheckboxChange,
  handleSearch,
  filterOptions,
  filteroptionData,
  setFilterOptions,
  setSearchTerm,
  filterCount,
  searchTerm,
  handleReset,
  bookAlias,
  filerStyle,
  setFilterPills,
  filterPills,
  setSwiper,
  handleContentClicked,
  indexValue,
  bookData,
  filteredResultCount,
}) {
  return (
    <>
      <Stack flexDirection={"row-reverse"}>
        {bookAlias && bookData && (
          <CommonDrawer
            drawerOpen={openDrawer}
            setDrawerOpen={handleClose}
            returnFocusOnClose={false}
            drawerHeader={
              <DrawerHeader
                imageTitle={imageTitle}
                data={bookData}
                handleClose={handleClose}
                masterlist={masterlist}
              />
            }
            drawerBody={
              <DrawerBody
                setSwiper={setSwiper}
                data={bookData}
                handleBookClick={handleBookClick}
              // bibliographyObject={bibliographyObject}
              />
            }
            drawerFooter={
              <DrawerFooter
                data={bookData}
                // setShowSubscribeModal={setShowSubscribeModal}
                scanClicked={scanClicked}
                handleContentClicked={handleContentClicked}
                indexValue={indexValue}
              />
            }
          />
        )}
        {!showFilterBox && <FilterButton marginTop="40px" masterlistType={masterlistType} onClick={handleFilterClick} />}
        <Container
          maxW="auto"
          className="songs"
          px={{ base: "4", md: "0px" }}
          py={masterlistType === "generic"? "40px":"8"}
          position={"relative"}
        >
          <Stack flexDirection={"row"} gap={4} justifyContent="center">
            {data && data.length > 0 ? (
              <Stack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                marginRight={{ base: "0px", md: "30px" }}
                alignItems={"flex-start"}
              >
          {((filterPills &&  filterPills!==undefined && filterCount>0) || filterOptions?.search!=="") &&
          <FilterPills
          filters={filterPills}
          setFilterOptions={setFilterOptions}
          setApply={setApply}
          setSearchTerm={setSearchTerm}
          setFilterPills={setFilterPills}
          filteredResultCount={filteredResultCount}
          />
          }
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    <Box textAlign="center" marginLeft={"50%"}>
                      <Stack marginTop={"20px"}>
                        <img
                          src={loaderImg}
                          width={"100px"}
                          height={"100px"}
                          alt="Loading"
                        />
                      </Stack>
                    </Box>
                  }
                  endMessage={<NoMoreToLoad />}
                >
                  <Grid
                    data={data}
                    handleDrawer={handleDrawer}
                    spacing={{base:"10px",md:"24px"}}
                    columns={{ base: 2, md: 3, lg: 4, xl: 4 }}
                    component={ArchiveGrid}
                    border={true}
                  />
                </InfiniteScroll>
              </Stack>
            ) 
            : (
              <>
                <Stack
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/1`)
                  }
                  margin={"auto"}
                >
                  <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                   {filterPills &&  filterPills!==undefined && filterCount>0&&
               <FilterPills
               filters={filterPills}
               setFilterOptions={setFilterOptions}
               setApply={setApply}
               setFilterPills={setFilterPills}
                  />
          }
          </Box>
                  <DataNotFound />
                </Stack>
              </>
            )}
            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
              pos={masterlistType === "generic" && "relative"}
              right={masterlistType === "generic" && "-5px"}
            >
              {showFilterBox && (
                <FilterBox
                  setShowFilterBox={setShowFilterBox}
                  setApply={setApply}
                  explore={explore}
                  sort={sort}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                  filteroptionData={filteroptionData}
                  setFilterOptions={setFilterOptions}
                  setSearchTerm={setSearchTerm}
                  filterCount={filterCount}
                  searchTerm={searchTerm}
                  handleReset={handleReset}
                  className={filerStyle? filerStyle : "box-filter-film"}
                />
              )}
            </HStack>
          </Stack>
          {/* {
        showSubscribeModal &&
        <SubscriptionModal setShowSubscribeModal={setShowSubscribeModal} />
      } */}
          {contentClicked && (
            <ContentModal
              contentClicked={contentClicked}
              setContentClicked={setContentClicked}
              contentImage={bookData?.content_page_image}
            />
          )}
          {scanClicked && (
            <SliderModal
              scanClicked={scanClicked}
              setScanClicked={setScanClicked}
              sliderModalImg={bookData?.scanned_pages_image}
            />
          )}
        </Container>
      </Stack>
    </>
  );
}
export default GridView;