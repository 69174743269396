import { Box, Container, HStack, Stack } from "@chakra-ui/react";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import { explore } from "../../constants/constants";
import { CopyPasteService } from "../../services/CopyPaste";
import InfiniteScroll from "react-infinite-scroll-component";
import TimeLine from "../../FunctionalComponents/TimeLine/TimeLine";
import { FilterBox } from "../../FunctionalComponents/Filter/FilterBox";
import CommonDrawer from "../../FunctionalComponents/CommonDrawer/CommonDrawer";
import DrawerHeader from "../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerHeader";
import DrawerBody from "../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerBody";
import DrawerFooter from "../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerFooter";
import { FilterButton } from "../../CommonComponents/FilterButton";
import NoMoreToLoad from "../../CommonComponents/NoMoreToLoad";
import { DataNotFound } from "../../CommonComponents/DataNotFound";
import ThumbnailListView from "../../FunctionalComponents/ThumbnailListView/ThumbnailListView";
import FilterPills from "../FilterPills/FilterPills";
import ContentModal from "../../pages/Film/ResearchCentre/Library/ContentModal";
import SliderModal from "../../pages/Film/ResearchCentre/Library/SliderModal";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

function TabularView({
  handleBookClick,
  contentClicked,
  scanClicked,
  setContentClicked,
  setScanClicked,
  openDrawer,
  setOpenDrawer,
  tableName,
  masterlist,
  masterlistType = "generic",
  imageTitle,
  //new data props
  fetchMoreData,
  data,
  drawerBook,
  handleClose,
  showFilterBox,
  handleFilterClick,
  hasMore,
  handleDrawer,
  setShowFilterBox,
  setApply,
  sort,
  handleCheckboxChange,
  handleSearch,
  filterOptions,
  filteroptionData,
  setFilterOptions,
  setSearchTerm,
  filterCount,
  searchTerm,
  handleReset,
  bookAlias,
  filerStyle,
  filterPills,
  setFilterPills,
  removeFilter,
  handleContentClicked,
  setSwiper,
  indexValue,
  bookData,
  filteredResultCount

}) {
  const btnRef = React.useRef();

  return (
    <>
      <Stack flexDirection={"row-reverse"}>
      {bookAlias && bookData && (
          <CommonDrawer
            drawerOpen={openDrawer}
            setDrawerOpen={handleClose}
            returnFocusOnClose={false}
            drawerHeader={
              <DrawerHeader
                imageTitle={imageTitle}
                data={bookData}
                handleClose={handleClose}
                masterlist={masterlist}
              />
            }
            drawerBody={
              <DrawerBody
                setSwiper={setSwiper}
                data={bookData}
                handleBookClick={handleBookClick}
              // bibliographyObject={bibliographyObject}
              />
            }
            drawerFooter={
              <DrawerFooter
                data={bookData}
                // setShowSubscribeModal={setShowSubscribeModal}
                scanClicked={scanClicked}
                handleContentClicked={handleContentClicked}
                indexValue={indexValue}
              />
            }
          />
        )}
        {!showFilterBox && <FilterButton marginTop="40px" masterlistType={masterlistType} onClick={handleFilterClick} />}
        <Container
          maxW="auto"
          className="songs"
          px={{ base: "4", md: "0px" }}
          py={8}
          position={"relative"}
        >
          <Stack flexDirection={"row"} gap={0} justifyContent="space-between">

            {data && data.length > 0 ? (
              <Stack
                flexDirection={"column"}
                gap={0}
                height="100%"
                onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/0`)}
                width={"90%"}
              >
                 {filterPills &&  filterPills!==undefined && 
          <FilterPills
          filters={filterPills}
          setFilterOptions={setFilterOptions}
          setApply={setApply}
          setSearchTerm={setSearchTerm}
          setFilterPills={setFilterPills}
          filteredResultCount={filteredResultCount}
          />
          }
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    <Box textAlign="center" marginLeft={"50%"}>
                      <Stack marginTop={"20px"}>
                        <img
                          src={loaderImg}
                          width={"100px"}
                          height={"100px"}
                          alt="Loading"
                        />
                      </Stack>
                    </Box>
                  }
                  endMessage={<NoMoreToLoad />}
                >
                  <TimeLine
                    data={data}
                    handleDrawer={handleDrawer}
                    btnRef={btnRef}
                    component={ThumbnailListView}
                    facets={filteroptionData}
                    sort={sort}
                  />
                </InfiniteScroll>
              </Stack>
            )
              : (
                <Stack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                margin={"auto"}
              >
                <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                 {filterPills &&  filterPills!==undefined && filterCount>0&&
             <FilterPills
             filters={filterPills}
             removeFilter={removeFilter}
             setFilterOptions={setFilterOptions}
             setApply={setApply}
             setFilterPills={setFilterPills}
                />
        }
        </Box>
                <DataNotFound />
              </Stack>
              )}
            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
              pos={masterlistType === "generic" && "relative"}
              right={masterlistType === "generic" && "-5px"}
            >
              {showFilterBox && (
                <FilterBox
                  setShowFilterBox={setShowFilterBox}
                  setApply={setApply}
                  explore={explore}
                  sort={sort}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                  filteroptionData={filteroptionData}
                  setFilterOptions={setFilterOptions}
                  setSearchTerm={setSearchTerm}
                  filterCount={filterCount}
                  searchTerm={searchTerm}
                  handleReset={handleReset}
                  className={filerStyle? filerStyle : "box-filter-film"}
                />
              )}
            </HStack>
          </Stack>
        </Container>
        {/* {
        showSubscribeModal &&
        <SubscriptionModal setShowSubscribeModal={setShowSubscribeModal} />
      } */}
        {contentClicked && (
          <ContentModal
            contentClicked={contentClicked}
            setContentClicked={setContentClicked}
            contentImage={bookData?.content_page_image}
            masterlistType={masterlistType}
          />
        )}
        {scanClicked && (
          <SliderModal
            scanClicked={scanClicked}
            setScanClicked={setScanClicked}
            sliderModalImg={bookData?.scanned_pages_image}  masterlistType={masterlistType}        />
        )}
      </Stack>
    </>
  );
}
export default TabularView;