import {
  HStack,
  IconButton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";
import React from "react";
import { BsGrid } from "react-icons/bs";
import { Link } from "react-router-dom";
import { filmography } from "../../constants/constants";

export default function TabMain({
  data,
  flag,
  aliasProp,
  modalcard,
  listGridIcon,
  tabHandle,
  setVisible,
  visible,
  attr,
  setAttr,
  creditsView,
  fullCreditsView,
  setFullCreditsView,
  setCastTabFlag,
  panelTop,
  tabTop,
  pos,
  topTogleIcon,
  width="1120px",
  position="fixed",
  listGridIconLeft,
  right="185px !important",
  id,
  listId="listgridbutton",
  overflow,
  top="63px",
  paddingTop="4",
  gap="30px"
}) {
  return (
    <Tabs
      variant="custom"
      defaultIndex={flag > 0 ? 1 : 0}
      index={attr && attr}
      width={"100%"}
      onChange={(index) => setAttr && setAttr(index)}
      overflow={overflow}
    >
      <TabList
        position={pos ? pos : "sticky"}
        width={{ base: "100%", md: width }}
        marginTop={tabTop && tabTop}
        top={top}
        px="0px"
        paddingTop={paddingTop}
        gap={gap}
        zIndex={modalcard ? 9999 : aliasProp ? 0 : 99}
      >
        {data.length > 0 &&
          data.map((item, index) => 
            {
              return( 
            <Tab
              fontSize={"16px"}
              px="0"
              mt="2"
              paddingBottom='5px'
              as={item.link && Link}
              to={item.link && `${item.link}`}
              onClick={() => tabHandle && tabHandle(item.list)}
              className={index === 4 ? "" : "Timeline"}
              _selected={{
                borderBottom: "4px",
                borderColor: "color.gray",
                fontWeight:"700",
                outline: "none",
                paddingBottom:'1px'
              }}
            >
              {/* {`${item.name}${item.count > 0 ? ` (${item.count})` : ''}`} */}
              {`${item.name}${item.count > 0 ? ` (${Number(item.count).toLocaleString()})` : ''}`}
              </Tab>
          )})}
      </TabList>
      {listGridIcon && (
        <Stack
          flexDirection={"row"}
          gap={0}
          zIndex={999}
          position={position}
          left={listGridIconLeft && listGridIconLeft}
          right={!listGridIconLeft && right}
          top={topTogleIcon ? topTogleIcon : "85px"}
          id={listId}
        >
          <HStack marginTop={"0px!important"}>
            <IconButton
              variant="unstyled"
              onClick={() => setVisible(false)}
              color={!visible ? "color.primary" : "color.lightgray2"}
              fontSize="20px"
              display="flex"
              borderTopLeftRadius="6px"
              borderBottomLeftRadius="6px"
              borderTopRightRadius="0px"
              borderBottomRightRadius="0px"
              border="1px"
              borderColor={"color.lightgray2"}
              height="32px"
            >
              <RiMenuLine ml="4" />
            </IconButton>
          </HStack>
          <HStack marginTop={"0px!important"}>
            <IconButton
              variant="unstyled"
              onClick={() => setVisible(true)}
              color={visible ? "color.primary" : "color.lightgray2"}
              fontSize="16px"
              display="flex"
              borderTopLeftRadius="0px"
              borderBottomLeftRadius="0px"
              borderTopRightRadius="6px"
              borderBottomRightRadius="6px"
              border="1px"
              borderColor={"color.lightgray2"}
              height="32px"
            >
              <BsGrid />
            </IconButton>
          </HStack>
        </Stack>
      )}
      {creditsView && (
        <Stack flexDirection={"row"} gap={0} id={id ? id : "listgridviewbtn1"}>
          <HStack
            marginTop={"13px!important"}
            border={"1px"}
            borderColor={"color.lightgray4"}
            borderRadius={"6px"}
            px={2}
            flexDirection={"row"}
            display={"flex"}
          >
            <Text
              fontSize={"14px"}
              fontWeight={!fullCreditsView ? "700" : "400"}
              lineHeight={"16px"}
              py={2}
              color={"color.primary"}
              onClick={() => {
                setFullCreditsView(false);
                setCastTabFlag(true);
              }}
              cursor={"pointer"}
            >
              {filmography.CAST_VIEW}
            </Text>
            <Text
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"16px"}
              py={2}
              marginRight="8px"
              color={"color.primary"}
            >
              |
            </Text>
            <Text
              fontSize={"14px"}
              fontWeight={fullCreditsView ? "700" : "400"}
              lineHeight={"16px"}
              py={2}
              marginRight="8px"
              color={"color.primary"}
              onClick={() => {
                setFullCreditsView(true);
                setCastTabFlag(true);
              }}
              cursor={"pointer"}
            >
              {filmography.FULL_CREDITS_VIEW}
            </Text>
          </HStack>
        </Stack>
      )}
      <TabPanels bg="color.secondary" marginTop={panelTop && panelTop}>
        {data.length > 0 &&
          data.map((item) => (
            <TabPanel px="0px" pt={"0px"}>
              {item.component}
            </TabPanel>
          ))}
      </TabPanels>
    </Tabs>
  );
}
