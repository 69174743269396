import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Services file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component files
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import BookletSongs from "./BodyOfWork/BookletSongs";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";

//Language files
import { explore } from "../../constants/constants";
// import FilmoGraphyTabpanel from "./BodyOfWork/Tabs/FilmoGraphyTabpanel";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import FilmoGraphyTabpanel from "../../components/Filmography/FilmoGraphyTabpanel";

export default function BodyofWork({ children }) {
  const { isOpen, onClose } = useDisclosure();
  const [bookletSong, setBookletSong] = React.useState(false);
  const [flag, setFlag] = useState(0);
  const {alias}=useParams();
  const masterlist = alias;
  const masterlistType="person-poly"
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState(null);

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        masterlist
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].alias
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item.key === "subject").value +"."+result[0].find((item) => item.key === "masterlist_type").value,
          }
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(masterlistType,
        masterlist
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist_alias")?.value}/${
              it?.find((item) => item?.key === "landing_page_abbreviation")?.value
            }/${it.find(
              (item) =>
                item.key === "landing_page_abbreviation"
            )?.value==="research-centre" ?`library`:``}
             `,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
    window.scrollTo(0, 0);
  }, [masterlist]);
  
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        bookletSong={bookletSong}
        setBookletSong={setBookletSong}
        flag={flag}
        setFlag={setFlag}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        alias={alias}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            bookletSong={bookletSong}
            setBookletSong={setBookletSong}
            flag={flag}
            setFlag={setFlag}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <BodyOfWorkBody
          bookletSong={bookletSong}
          flag={flag}
          setBookletSong={setBookletSong}
          masterlist={masterlist}
          masterlistType={masterlistType}
          aliasAPI={alias}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  bookletSong,
  setBookletSong,
  flag,
  setFlag,
  horislinkData,
  artistDetails,
  introData,
  alias,
  masterlistType,
  ...rest
}) => {
  const oSections = [
    {
      to: `/${masterlistType}/${alias}/posts`,
      title: "",
      text: `${explore.POSTS} | ${explore.INSIGHTS}`,
    }
  ];

  const navigate = useNavigate();
  const handleNavigation = () => {
    if (bookletSong) {
      setFlag((prev) => prev + 1);
      setBookletSong(false);
    } else {
      navigate(-1);
    }
  };
  return (
    <>
    <BottomMainMenu />
    <CommonLeftSidebar
      sections={introData}
      oSections={oSections}
      hMenu={horislinkData}
      artistDetails={artistDetails}
      handleNavigation={handleNavigation}
      bookletSong={bookletSong}
      leftFlag={true}
      aliasAPI={alias}
    />
    </>
  );
};

export const BodyOfWorkBody = ({ 
  bookletSong, 
  setBookletSong, 
  flag, 
  masterlist, 
  masterlistType,
  aliasAPI, 
}) => {
  const [modalcard, setModalcard] = useState(false);
  const navigate = useNavigate();
  let { gridview ,bookletAlias } = useParams();
  const tableName = "filmographies";
  const [dataCount,setDataCount]=useState(0);
  React.useEffect(() => {
    if (gridview) {
      navigate(`/${masterlistType}/${masterlist}/filmography`, { replace: true });
    }
  }, [gridview, masterlist, masterlistType, navigate]);

  const handlePrint = () => {
    window.print();
  };

  function formatData() {
    return [
      {
        name: `${explore.FILMOGRAPHY} ${dataCount >0 ? `(${dataCount})`:''}`,          
        component:<FilmoGraphyTabpanel handlePrint={handlePrint} masterlist={masterlist} masterlistType={masterlistType} tableName={tableName} setDataCount={setDataCount} />,
      }
    ];
}

const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(masterlist){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist,tableName,dataCount])
  return (
    <Stack height={"100%"}>
    <Container maxW="auto" px={{ base: "4", md: "6" }} mx={"0"}>
      <Stack px={{ base: "0", md: "8" }}>
        {bookletAlias ? (
          <Box
          minHeight={{xl:"100%", '2xl':"100%)"}}
            px="0px"
          >
           <BookletSongs masterlist={masterlist} masterlistType={masterlistType} bookletAlias={bookletAlias} />
          </Box>
        ) : (
          <TabMain  paddingTop="10px" gap="50px" width={"1080px"} cla pos={"fixed"}
          panelTop={{ base: "65px", sm: "85px", md: "65px" }} topTogleIcon="25px" listId={"listgridbutton6"} flag={flag} data={tabPanelData} modalcard={modalcard}  setModalcard={setModalcard} />
        )}
        </Stack>
      </Container>
    </Stack>
  );
};
