import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Image,
  Container,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import dateFormat from "dateformat";
//Services files
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component files
import Large from "../../components/layouts/Typography/Large";
import H2 from "../../components/layouts/Typography/H2";
import ReadMore from "../PersonalityPolymath/ReadMore";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";

//Language files
import { explore } from "../../constants/constants";
import DrawerFamilyDetails from "../../FunctionalComponents/Personality_Introduction/Drawer/Personalia/DrawerFamilyDetails";
import DrawerModalDetails from "../../FunctionalComponents/Personality_Introduction/Drawer/Personalia/DrawerModalDetails";
import Accordian from "../../FunctionalComponents/Accordion/Accordian";
import AccordianText from "../../FunctionalComponents/Accordion/AccordianText";
import AccordianPersonalia from "../../FunctionalComponents/Accordion/AccordianPersonalia";
import AccordianEduction from "../../FunctionalComponents/Accordion/AccordianEduction";
import AccordianTeaching from "../../FunctionalComponents/Accordion/AccordianTeaching";
import { BodyOfWorkBody } from "./BodyOfWork";
import { EconomicsOfCinemaBody } from "./EconomicsOfCinema";
import { IntegratedTimelineBody } from "../../FunctionalComponents/IntegratedTimeline/IntegratedTimeline";
import { LibraryAndArchive } from "../../components/ResearchCentre/LibraryAndArchive";
import { getImageURL } from "../../util/getImage";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`;

export default function Introduction({ children }) {
  const [title, setTitle] = React.useState();
  const [date, setDate] = React.useState();
  const [datedod, setDateDod] = React.useState();
  const { isOpen, onClose } = useDisclosure();
  const [readMoreText, setReadMore] = useState(false);
  const { alias } = useParams();
  const aliasAPI = alias;
  const masterlistType = "person-poly";
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState(null);
  const [bookletSong, setBookletSong] = React.useState(false);
  const [flag, setFlag] = useState(0);
  const [modalcard, setModalcard] = useState(false);
  const [researchCentreFlag, setResearchCentreFlag] = React.useState(true);
  const [bookFlag, setBookFlag] = useState(false);
  const [leftFlag, setleftFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bookReadmore, setBookReadmore] = useState(false);
  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${ result[0].find((item) => item?.key === "researchCategories")
              ?.value?.length>0 ?
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].alias :''
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title:
              result[0].find((item) => item.key === "subject").value +
              "." +
              result[0].find((item) => item.key === "masterlist_type").value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${
              it?.find((item) => item?.key === "masterlist_alias")?.value
            }/agraphy/${
              it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value
            }/${
              it.find((item) => item.key === "landing_page_abbreviation")
                ?.value === "research-centre"
                ? `all`
                : ``
            }
             `,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasAPI]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh)"
        title={title}
        date={date}
        datedod={datedod}
        readMoreText={readMoreText}
        setReadMore={setReadMore}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        masterlistType={masterlistType}
        setFlag={setFlag}
        researchCentreFlag={researchCentreFlag}
        leftFlag={leftFlag}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            title={title}
            date={date}
            datedod={datedod}
            readMoreText={readMoreText}
            setReadMore={setReadMore}
            aliasAPI={aliasAPI}
          />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <Routes>
          <Route
            path="/introduction"
            element={
              <IntroductionBody
                setTitle={setTitle}
                setDate={setDate}
                setDateDod={setDateDod}
                readMoreText={readMoreText}
                setReadMore={setReadMore}
                aliasAPI={aliasAPI}
                masterlistType={masterlistType}
              />
            }
          />
          <Route
            path="/body-of-work/:bookletAlias?"
            element={
              <BodyOfWorkBody
                bookletSong={bookletSong}
                flag={flag}
                setBookletSong={setBookletSong}
                masterlist={aliasAPI}
                masterlistType={masterlistType}
                aliasAPI={alias}
              />
            }
          />
          <Route
            path="/economics-of-cinema"
            element={
              <EconomicsOfCinemaBody
                masterlist={alias}
                masterlistType={masterlistType}
              />
            }
          />
          <Route
            path="/integrated-timeline"
            element={
              <IntegratedTimelineBody
                modalcard={modalcard}
                setModalcard={setModalcard}
                aliasAPI={aliasAPI}
                masterlistType={masterlistType}
              />
            }
          />
          <Route
            path="/research-centre/:tabValue?/:bookAlias?/:bookDetails?"
            element={
              <LibraryAndArchive
                masterlist={alias}
                masterlistType={masterlistType}
                setResearchCentreFlag={setResearchCentreFlag}
                bookFlag={bookFlag}
                setBookFlag={setBookFlag}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                setleftFlag={setleftFlag}
                bookReadmore={bookReadmore}
                setBookReadmore={setBookReadmore}
                className={"top-to-btm4"}
              />
            }
          />
        </Routes>
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  readMoreText,
  setReadMore,
  aliasAPI,
  horislinkData,
  artistDetails,
  introData,
  masterlistType,
  researchCentreFlag,
  leftFlag,
  ...rest
}) => {
  const data = {
    oSections: [
      {
        to: `/${masterlistType}/${aliasAPI}/posts`,
        title: "",
        text: explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/insights`,
        title: "",
        text: explore.INSIGHTS,
      },
    ],
    backNavigation: `/${masterlistType}/${aliasAPI}`,
  };

  const navigate = useNavigate();
  const handleNavigation = (alias) => {
    if (readMoreText) {
      setReadMore(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <CommonLeftSidebar
        sections={introData}
        oSections={data.oSections}
        hMenu={horislinkData}
        artistDetails={artistDetails}
        backTo={data.backNavigation}
        handleNavigation={handleNavigation}
        leftFlag={leftFlag ? leftFlag : true}
        readMoreText={readMoreText}
        aliasAPI={aliasAPI}
        researchCentreFlag={researchCentreFlag}
      />
    </>
  );
};

const IntroductionBody = ({
  setTitle,
  setDate,
  setDateDod,
  readMoreText,
  setReadMore,
  aliasAPI,
  masterlistType,
}) => {
  const [educationFullScreenImage, setEducationFullScreenImage] =
    useState(false);
  const [educationImgData, setEducationImgData] = useState(null);
  const [familyFullScreenImage, setFamilyFullScreenImage] = useState(false);
  const [familyImgData, setFamilyImgData] = useState(null);
  const [contentAwaited, setContentAwaited] = useState(false);
  const [accordianData, setAccordianData] = useState([]);
  const masterlistTitle = aliasAPI;
  const [masterlistImage, setMasterListImage] = useState("");

  const showEducationImages = (images, flag) => {
    if (images) {
      setEducationImgData(images);
    } else {
      setEducationFullScreenImage(flag);
      setEducationImgData(null);
    }
  };

  const showFamilyMemberImages = (images, flag) => {
    if (images) {
      setFamilyImgData(images);
    } else {
      setFamilyFullScreenImage(flag);
      setFamilyImgData(null);
    }
  };

  const showFullImages = (flag) => {
    setFamilyFullScreenImage(flag);
  };

  React.useEffect(() => {
    const getIntroData = async () => {
      let preface = "";
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        masterlistTitle
      );
      const personalia = await getIntroductionData.getPersonaliaByAlias(
        masterlistType,
        masterlistTitle
      );
      const education = await getIntroductionData.getEducationByAlias(
        masterlistType,
        masterlistTitle
      );
      const teaching = await getIntroductionData.getTeachingInfraByAlias(
        masterlistType,
        masterlistTitle
      );

      if (result) {
        setContentAwaited(false);
        setMasterListImage(
          result[0].find((item) => item?.key === "image_alias")?.value
        );
        preface = result[0].find((item) => item?.key === "description")?.value;
      } else {
        setContentAwaited(true);
      }

      if (preface !== "" && personalia && education) {
        const formattedData = formatData(
          preface,
          personalia,
          education,
          categorizeData(teaching)
        );
        setAccordianData(formattedData);
      }
    };
    getIntroData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlistTitle, masterlistType]);
  function formatData(introData, personalia, educationData, teachingData) {
    return [
      {
        name: `${explore.PREFACE}`,
        data: introData,
        component: <AccordianText data={introData} />,
      },
      {
        name: `${explore.PERSONALIA}`,
        data: personalia,
        component: (
          <AccordianPersonalia
            personalia={personalia}
            showFamilyMemberImages={showFamilyMemberImages}
          />
        ),
      },
      {
        name: `${explore.EDUCATION}`,
        data: educationData,
        component: (
          <AccordianEduction
            educationData={educationData}
            showEducationImages={showEducationImages}
          />
        ),
      },
      {
        name: `${explore.TEACHING_INFRA_B}`,
        data: teachingData,
        component: (
          <AccordianTeaching
            teachingData={teachingData}
            showFamilyMemberImages={showFamilyMemberImages}
          />
        ),
      },
    ];
  }
  const categorizeData = (data) => {
    const categorized = {};
    data.forEach((item) => {
      const { post } = item;
      const key = post;
      if (!categorized[key]) {
        categorized[key] = [];
      }
      categorized[key].push(item);
    });
    return categorized;
  };
  return (
    <>
      {readMoreText ? (
        <ReadMore />
      ) : (
        <>
          <Stack>
            {contentAwaited ? (
              <Text
                py={"370px"}
                fontSize={"45px"}
                fontWeight="700"
                textAlign="center"
              >
                Content Awaited
              </Text>
            ) : (
              <>
                <Container maxW="auto" px="0" className="songs">
                  <Stack
                    height="calc(100vh - 64px)"
                    position={"relative"}
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    <HStack px="0" width={{ base: "100%", md: "775px" }}>
                      <Image
                         src={getImageURL(masterlistImage)}
                        fallbackSrc={placeholderImg}
                        width={"100%"}
                        height={"100%"}
                        objectFit={"cover"}
                        objectPosition={"top"}
                      />
                    </HStack>
                    <HStack
                      zIndex={"99"}
                      alignItems="flex-start"
                      overflowY={"scroll"}
                      borderLeft={"1px"}
                      borderColor={"#e8e8e8"}
                      className="mt-0"
                    >
                      <Box px="5" width={{ base: "100%", md: "425px" }}>
                        {accordianData.length > 0 && (
                          <Accordian data={accordianData} />
                        )}
                      </Box>
                    </HStack>
                  </Stack>
                </Container>
                <DrawerFamilyDetails
                  familyImgData={familyImgData}
                  educationImgData={educationImgData}
                  showFamilyMemberImages={showFamilyMemberImages}
                  showEducationImages={showEducationImages}
                  showFullImages={showFullImages}
                  closeIcon={closeIcon}
                  educationFullScreenImage={educationFullScreenImage}
                  width="765px"
                />
                <DrawerModalDetails
                  familyImgData={familyImgData}
                  educationImgData={educationImgData}
                  showFullImages={showFullImages}
                  familyFullScreenImage={familyFullScreenImage}
                />
                <Stack display={{ base: "flex", md: "none" }}>
                  <BottomMainMenu />
                </Stack>
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export function ArtistDetails({ artistDetails }) {
  return (
    <>
      <HStack justifyContent="left" py="1" px="4" marginTop="0.0rem">
        <H2>{artistDetails?.name}</H2>
      </HStack>
      <HStack justifyContent="left" px="4">
        <Large color={"black"}>
          {dateFormat(artistDetails?.date_of_birth, "d mmm yyyy")} -{" "}
          {dateFormat(artistDetails?.date_of_death, "d mmm  yyyy")}
        </Large>
      </HStack>
    </>
  );
}
